.storylet-root__description-container {
  // We need to override the light-blue colour set on a
  a {
    color: $inverse-link-color;
    &:hover {
      color: $inverse-link-hover-color;
    }
  }
}

.storylet-root__card > div {
  padding: 8px 8px 8px 8px;
}

.storylet-root__card-image {
  width: 93px;
}

.storylet-root__frequency {
    float: right;
    margin-left: 8px;
}

.storylet-root__heading {

}

.storylet-root__description-container ul {
  list-style-position: inside;
  margin-bottom: 10px;
}

.storylet-root li {

}
