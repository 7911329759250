.avatar-list {
  display: grid;
  // grid-template-columns: repeat(auto-fill, 120px);
  grid-template-columns: 1fr 1fr 1fr;
  @media (min-width: $screen-xs-min) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (min-width: $screen-sm-min) {
    grid-template-columns: repeat(6, 1fr);
  }

  grid-template-rows: repeat(auto-fill, 120px);
}

.avatar-list__item {
  cursor: pointer;
  margin-bottom: 1rem;
  text-align: center;
  transition: transform 0.1s ease-in-out;
  // width: 33%; // 3 per line on xxs devices
  @media (min-width: $screen-xs-min) {
    // width: 20%; // 5 per on landscape phones -> tablets
  }

  @media (min-width: $screen-sm-min) {
    // width: calc(100% / 6);
  }
}

.avatar-list__image {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
  display: block;
  transition: scale 0.1s ease-in-out;
}

.avatar-list__item:hover .avatar-list__image {
  transform: scale(1.04);
}

.avatar-list__item--disabled {
  cursor: default;
}

.avatar-list__item--disabled > div {
  // This !important is needed to override the inline style given by react-interactive
  cursor: default !important;
}

.avatar img {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}

.avatar {
  cursor: pointer;
  margin: 0 5px;
  border: 2px solid transparent;
}

.avatar:hover img {
  box-shadow: 0 0 5px 5px #92d1d5;
  cursor: pointer;
}

.avatar--selected img {
  box-shadow: 0 0 5px 5px #92d1d5;
  cursor: pointer;
}
