/****************************************************************************
 * LOADING STYLES
 *
 * Used to create loading messages within the app
 */

.fl-ico--spin {
    animation: spin 1000ms infinite linear;
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }

    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }

    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}

.loading-hat {
  width: 100px;
  text-align: center;
}

.loading-image {
  z-index: 100;
  // margin: auto;
  text-align: center;
  // transform-origin: 50% 50%;
}

.loading-image span {
  transform-style: preserve-3d;
}

.loading-spinner {
  margin: auto;
  margin-top: 10px;
  text-align: center;
}

.loading-spinner--small {
  margin-top: 0;
}

.loading-spinner--no-margins {
  margin: 0;
}

.fl-ico--loading{
  animation: turn 3s infinite;
}

@keyframes turn {
    0% {
      -webkit-transform: rotateY(0deg); 
      -webkit-transform: translateZ(0);
    }
    50% {
      -webkit-transform: rotateY(180deg);
      -webkit-transform: translateZ(1000);
    }
    100% {
      -webkit-transform: rotateY(360deg);
      -webkit-transform: translateZ(0);
    }
}
