.message {
  flex: 1;
  width: calc(100% - 0.5rem);

  @media (min-width: $screen-md-up) {
    &:first-child {
      margin-right: 1rem;
    }
  }
}

.message__buttons {
  margin-top: 0;
}

.message__email-button-container,
.message__delete-button-container {
  position: relative;
  float: right;
  right: -4px;
}

.message__description {
  margin-bottom: 0.25rem;

  br:nth-of-type(2) {
    display: none;
  }

  & a {
    color: $blue-dark;
  }
}

.message__heading {
  display: inline;
  font-family: inherit;
}

.message__image {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.message__left {
  @media (min-width: $screen-sm-up) {
    float: left;
  }

  padding-bottom: 0.5rem;
  padding-right: 0.5rem;
}
