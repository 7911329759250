.they-say__body {
  box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.5);
  border: 2px solid #3e3e3e;
  padding: 0.5rem 1rem;
  width: 100%;
}

.they-say__header-row {
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  align-items: self-start;
}

.they-say__heading {
  color: $white;
}

.they-say__quote {
  font-family: $font-family-serif;
  position: relative;
  top: .1rem;
  &:first-of-type {
    margin-right: .2rem;
  }
  &:last-of-type {
    margin-left: .2rem;
  }
}

.they-say__content {
  color: $beige-light;
  font-family: $font-family-serif;
  font-weight: $font-weight-medium;
  text-align: left;
}

.they-say__content::before {
  content: '“';
}

.they-say__content::after {
  content: '”';
}
