.act__add-and-suggest-buttons {
  &:after {
    content: '';
    display: none;
  }
}

.act__add-contact-message {
  height: 1rem;
  margin-bottom: .5rem;
}

.act__contact-choice-label {
  width: 100%;
}

.act__contact-choice-methods {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  @media (min-width: $screen-md-up) {
    flex-direction: row;
  }
}

.act__contact-choice-method {
  flex: 1;
}

.act__contact-choice-method--select-contact {
  @media (min-width: $screen-md-up) {
    margin-right: 1rem;
  }
}

.act__form-input {
  height: 40px; // TODO: unhack this
}

.act__ineligible-contacts {
  margin-top: .5rem;
}

.act__invitation-form-header {
  margin-bottom: 0;
}

.act__preset-invitation-text {
  margin-bottom: 0;
}

.act__quality-requirement {
  margin-right: 1rem;
}

.act__quality-requirement:last-of-type {
  margin-bottom: 0;
  margin-right: 0;
}

.act__quality-requirement-list-container {
  & > ul {
    list-style: none;
  }
  & > ul > li {
    margin-left: .5rem;
    margin-top: .25rem;
  }
}

.act__send-or-go-back {
  margin-top: 1rem;
}
