.possessions__lodgings-and-equipped {
    @media (min-width: $screen-lg-up) {
        display: flex;
    }
}
.possessions__lodgings-and-outfit-control {
    @media (min-width: $screen-lg-up) {
        margin-right: 1rem;
    }

    @media (max-width: $screen-md-down) {
        align-items: stretch;
        border-bottom: 2px solid #a89e8c;
        display: flex;
        flex: 1;
        flex-wrap: wrap-reverse;
        justify-content: space-around;
        margin-bottom: 17px;
        padding-bottom: 17px;
    }
}

.possessions__inventory-switcher {
    // display: flex;
    // flex-wrap: wrap-reverse;
}
