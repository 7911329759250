.branch {
  background-color: $beige-light;
  background-repeat: repeat;
  background-position: 0 0;
  box-shadow: 0px 2px 3px 0px $brown-light;
  margin-top: 1rem;
  margin-left: .5rem;
  padding: .5rem;

  // Add a :before pseudo element that has the inset border on it.
  // @include extra-border(before, (3px, 3px, 3px, 3px), 1px, $beige-mid);

  @media (min-width: $screen-sm-up) {
    display: flex;
    flex-wrap: wrap;
  }

  // Override the colour set on <a> tags
  a {
    color: $inverse-link-color;
    &:hover {
      color: $inverse-link-hover-color;
    }
  }
}

.branch::after {
  content: '';
  display: block;
  clear: both;
}

.branch__body {
  min-height: 100px; // branch body needs to be at least as tall as the branch card

  @media (min-width: $screen-sm-up) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.branch__body--force-clear-qreqs {
  @media (min-width: $screen-sm-up) {
    justify-content: flex-start;
  }
}

.branch__card {
  margin: 0;
}

.branch__left {
  @media (min-width: $screen-sm-up) {
    padding-bottom: 0;
  }
}

.branch__plan-buttonlet {
  float: right;
}

.branch__title {
  margin-bottom: 0;
}