.sidebar {
  color: #efefef;
}

.sidebar__echoes-button,
.sidebar__fate-button {
  box-shadow: none;
  display: inline-block;
  line-height: 1px;
  margin-right: 10px;
  vertical-align: middle;
}

.sidebar__button--has-focus-outline {
  outline: solid 1px rgba(255, 255, 255, 0);
  outline-offset: 2px;
  transition: outline-color .1s;

  &:focus {
    outline: solid 1px rgba(255, 255, 255, .8);
  }
}