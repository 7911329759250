.quality-item {
  display: flex;
  font-family: $font-family-serif;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.quality-item__body {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.quality-item__icon {
  margin-right: .75rem;
}