/**
 * Mixins for things that require multiple vendor prefixes.
 */

@mixin rotate($degrees) {
	-webkit-transform:	rotate($degrees);
	  -ms-transform:	rotate($degrees); // IE9 only
	   -o-transform:	rotate($degrees);
		  transform:	rotate($degrees);
}

@mixin translate3d($tx:0, $ty: 0, $tz: 0) {
	-webkit-transform:	translate3d($tx, $ty, $tz);
	-moz-transform:		translate3d($tx, $ty, $tz);
	-ms-transform:		translate3d($tx, $ty, $tz);
	-o-transform:		translate3d($tx, $ty, $tz);
	transform:			translate3d($tx, $ty, $tz);
}

/**
 * Radial gradients.
 * Pass it two hex colors. It makes a circular gradient, going from
 * $center-bg to $edge-bg.
 */
@mixin background-radial-gradient($center-bg, $edge-bg) {
	 /* Old browsers */
	background: $center-bg;
	 /* FF3.6+ */
	background: -moz-radial-gradient(center, ellipse cover, $center-bg 0%, $edge-bg 100%);
	/* Chrome,Safari4+ */
	background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, $center-bg), color-stop(100%, $edge-bg));
	/* Chrome10+,Safari5.1+ */
	background: -webkit-radial-gradient(center, ellipse cover, $center-bg 0%, $edge-bg 100%);
	/* Opera 12+ */
	background: -o-radial-gradient(center, ellipse cover, $center-bg 0%, $edge-bg 100%);
	/* IE10+ */
	background: -ms-radial-gradient(center, ellipse cover, $center-bg 0%, $edge-bg 100%);
	/* W3C */
	background: radial-gradient(ellipse at center, $center-bg 0%, $edge-bg 100%);
	/* IE6-9 fallback on horizontal gradient */
	// Can't get this working. Let's have no gradient on old IEs.
	//filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$center-bg', endColorstr='$edge-bg',GradientType=1 );
}
