.challenge-and-second-chance {
    @media (min-width: $screen-sm-up) {
        display: flex;
    }
}

.challenge {
    // background-color: #cbc3b3;
    clear: both;
    // padding: 5px 8px 8px;
    width: 100%;

    // Don't clear when the screen is just wide enough to float without linebreaks
    @media (min-width: $screen-sm-up) {
        clear: none;
        // display: flex;
        flex-wrap: nowrap;
    }

    @media (min-width: $screen-sm-up) {
        flex: 1;
        padding-right: 1rem;
    }
}

.challenge__heading {
    margin-bottom: 0;
    margin-right: 0;
}

.challenge__icon {
    img {
        height: 30px;
        width: 30px;
    }
}

.challenge__left {
    float: left;
    margin-left: .25rem;
    margin-right: .5rem;
}

$challenge-lightness: 30;

/* Challenge difficulties
----------------------------------------------------------*/
.diff6 {
    color: hsl(301, 57.84, $challenge-lightness);
    font-style: inherit;
}

.diff5 {
    color: hsl(358.56, 69.83, $challenge-lightness);
    font-style: inherit;
}

.diff4 {
    color: hsl(19.33, 88.89, $challenge-lightness);
    font-style: inherit;
}

.diff3 {
    color: hsl(39.83, 85, $challenge-lightness * .9);
    font-style: inherit;
}

.diff2 {
    color: hsl(196.32, 100, $challenge-lightness);
    font-style: inherit;
}

.diff1 {
    // Because this is a green, we need to drop the lightness a bit
    // to compensate
    color: hsl(136, 68, $challenge-lightness * .9);
    font-style: inherit;
}

.diff0 {
    color: inherit;
    font-style: inherit;
}
