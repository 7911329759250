.display-quality {
  transition: opacity .2s ease-in;
}

.display-quality--empty {
  &:hover {
    cursor: pointer;
  }
}

.display-quality--is-changing {
  opacity: 0.5;
  pointer-events: none;
}

.display-quality__image {
  margin-right: .5rem;
  position: relative;

  @media (min-width: $screen-sm-up) {
    float: left;
    margin-bottom: .25rem;
  }

  img {
    border: solid 2px rgb(56, 56, 56);
  }

  &:hover {
    img {
      transform: scale(1.05);
    }
  }
}

.display-quality__image-and-name {
  align-items: flex-start;
  display: flex;

  @media (min-width: $screen-sm-up) {
    display: block;
  }
}