// This is the portal where we add the close button for small devices
#close-map-button-root {
  pointer-events: none;
  position: fixed;
  top: 0;
  z-index: 501; // In front of the map overlay
}

#js-leaflet-map {
  // height: 80vh;
}

.leaflet-control-zoom {
}

.leaflet-control-zoom-in {
}

.leaflet-control-zoom-out {
}

.leaflet-interactive {
  cursor: default;
}

.leaflet-marker-icon {
  cursor: pointer;
}

/* Swap z-levels */

.leaflet-marker-pane {
  z-index: 650;
}

.leaflet-tooltip-pane {
  z-index: 600;
}

.map__border-fanciness {
  image-rendering: pixelated;
  height: 12px;
  opacity: 0;
  pointer-events: none;
  transition: opacity .2s, filter .2s;
}

.map__border-fanciness--selected {
}

.map__border-fanciness--visible {
  opacity: 1;
}

.map__close-button-container {
  display: flex;
  justify-content: flex-end;
  pointer-events: none;
  top: 0;
  width: 100vw;
}

.map__tilelayer--undiscovered {
  filter: hue-rotate(201deg) brightness(0.4) saturate(0.4);
}

.map__close-button {
  background: transparent;
  border: none;
  color: #ede3d2;
  height: 50px;
  padding-top: 10px;
  pointer-events: auto;
  text-align: center;
  width: 50px;
  text-shadow: 02px 3px #000000;
}

.map__label {
  background: #1d1d1d;
  border: 1px solid #2D2D2D;
  box-shadow: 0 2px 12px 3px #000000;
  color: #efefef;
  cursor: pointer;
  display: inline-block;
  height: auto;
  font-size: 0.8em;
  line-height: 1.2;
  padding: 0.5em;
  text-align: center;
  text-transform: uppercase;
}

.map__lodgings-button-container {
  align-items: flex-end;
  display: flex;
  flex-direction: row-reverse;
  position: absolute;
  bottom: 0;
  min-height: 190px;
  min-width: 348px;
  overflow-x: hidden;
  overflow-y: hidden;
  padding-bottom: 20px;
  pointer-events: none;
  width: 100%;
  z-index: 600;
}

.map__lodgings-button {
  cursor: pointer;
  display: block;

  &:hover {
    // filter: drop-shadow(0 0 6px #fff);
  }
}

.map__lodgings-button--locked {
  pointer-events: none;

  &:hover {
    filter: none;
  }
}

.map__lodgings-button--you-are-here {
  &:hover {
    filter: none;
  }
}

.map__lodgings-button__image {
  display: block;
  // width: 60px;
}

.map__lodgings-button--locked {
  cursor: default;
  filter: saturate(0) brightness(0.5);
}

.map__lodgings-button__image--you-are-here {
  cursor: default;
}

.map__lodgings-button__player-marker {
  bottom: 40px;
  cursor: grab;
  left: 3px;
  pointer-events: none;
  position: absolute;
}

.map__lodgings-gate-button {
  background: transparent;
  cursor: pointer;
  &:hover .map__lodgings-gate-icon {
    filter: sepia(1) saturate(2.4) hue-rotate(3deg);
  }
}

.map__player-marker.map__player-marker {
  pointer-events: none;
}

.map__player-marker {
  opacity: 0;
  transition: opacity .6s ease-in-out;
}

.map__player-marker--visible {
  opacity: 1;
}
