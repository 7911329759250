/**
 * BANNER.
 * The banner that contains an image across the top of the page.
 * And contains navigation at narrow widths.
 * There are different ones for different widths.
 */

// Common styles.
.banner {
  background-position: 50% 0;
  background-repeat: no-repeat;
}

/**
 * Shown at wider widths $screen-lg-up and over.
 */
.banner--lg-up {
  height: 350px;
  display: none;

  @media (min-width: $screen-lg-up) {
    display: block;
  }
}

/**
 * Shown at narrower widths (BELOW $screen-lg-up).
 */
.banner--md-down {
  background-size: cover;
  display: block;
  overflow: hidden;
  z-index: $z-index-banner-xs;
  width: 100%;
  height: $banner-xs-height;
  margin-left: auto;
  margin-right: auto;
  max-width: 60rem;

  span.header--img {
    width: 100%;
    height: 100%;
    transform: translate3d(0, 0, 0);
    position: absolute;
    top: 0;
    left: 0;
    background-position: 50% 0;
    background-repeat: no-repeat;
    z-index: -1;
  }
}

.banner__list--md-down {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

/**
 * Used within .banner--md-down for mobile nav.
 */
.banner__item {
  display: table-cell;
  width: 33%;

  a {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
    width: 50px;
    height: 50px;
    text-align: center;
    color: $header-button-color;
    text-shadow: 0 2px 3px #000000;

    & .fl-ico {
      background-color: $header-button-color;
    }

    & .fl-ico-2x {
      width: $base-icon-size * 2.5;
      height: $base-icon-size * 2.5;
    }

    &:hover,
    &:active {
      color: $header-button-active-color;

      & .fl-ico {
        background-color: $header-button-active-color;
      }
    }

    &.disabled {
      color: $gray-lightish;

      & .fl-ico {
        background-color: $gray-lightish;
      }
    }
  }

  &.currency--display {
    vertical-align: middle;

    a {
      font-size: 1.6em;
      margin-top: 0.3em;
      padding-right: 0.3em;
      width: auto;
      text-align: right;

      .fl-ico {
        width: 1.4em;
        height: 1.4em;
      }
    }
  }
}

.banner-item{
  list-style: none;
}

.banner-item:first-child .banner__button {
  margin-left: 0;
}

.banner-item:last-child .banner__button {
  margin-right: 0;
}

.banner__button.banner__button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
  width: 50px;
  height: 50px;
  text-align: center;
  color: $header-button-color;
  text-shadow: 0 2px 3px #000000;

  & .fl-ico {
    background-color: $header-button-color;
  }

  & .fl-ico-2x {
    width: $base-icon-size * 2.5;
    height: $base-icon-size * 2.5;
  }

  &:hover,
  &:active {
    color: $header-button-active-color;

    & .fl-ico {
      background-color: $header-button-active-color;
    }
  }

  &.disabled {
    color: $gray-lightish;

    & .fl-ico {
      background-color: $gray-lightish;
    }
  }
}
