/**
 * Lists
 *
 * Just use one of these classes to get the style of list you want.
 */

/**
 * A plain, vertical, unstyled list.
 */
.list--unstyled {
    padding-left: 0;
    list-style: none;
}

/**
 * A horizontal list.
 */
.list--padded {
    @extend .list--unstyled;

    li {
        padding-bottom: 11px;
    }
}

/**
 * A horizontal list.
 */
.list--horizontal {
    @extend .list--unstyled;

    li {
        display: inline-block;
    }
}

.list--bulleted {
    list-style: none;
    margin-bottom: 0.5em;
}

.list-item--bulleted {
    padding-left: 12px;
}

/**
 * A list item (in a horizontal list) with a '|' separator
 */
.list-item--separated {
    &:after {
        content: "|";
        margin-left: 5px;
        margin-right: 5px;
    }

    &:last-child:after {
        content: "";
        margin-left: 0;
        margin-right: 0;
    }
}
