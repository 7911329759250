.hand__border {
  padding: 7px;
}

// Here we are targeting the wrapper div created by react-interactive
.hand__border > div {
    position: relative;
}

// To cover the seam between images and card-border backgrounds, we
// add a div with a small inset box-shadow
.hand__border > div:after {
    border-radius: 1px;
    bottom: 0;
    box-shadow: inset 0 0 2px black;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.hand__card-container {
  padding: 0 7px;
}

.hand__card {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
  display: block;
  margin: 0;
  transform: translateZ(0);
  transition: transform 0.1s ease-in-out;
  backface-visibility: hidden;

  &:hover {
    transform: translateZ(0) scale(1.04);
  }
}

.hand__image {
  display: block;
  height: calc(125px - 8px - 8px); // final height minus padding
  width: calc((125px - 8px - 8px) * 100/130);
}

// TODO: avoid this specificity hack somehow. It's not clear how to do so, since
// the .card-border wrapper is created by the Image component
.hand__image, 
.hand__card .card-border {
  &, & .card-border {
    transform: translateZ(0);
  }
}
