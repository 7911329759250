.tab-content {
  margin-bottom: 80px;
  position: relative;

  &:before {
    background-color: #bdb29e;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    filter: brightness(0.95);
  }

  @media (min-width: $screen-lg-up) {
    margin-bottom: 2em;
  }

  @media (max-width: $screen-sm-min - 1) {
    margin-left: auto;
    margin-right: auto;
    max-width: 60rem;
  }

  @media (min-width: 61rem) and (max-width: $screen-sm-min - 1) {
    // border-bottom: none;
  }

  @media (min-width: $screen-sm-min) {
    // Add a :before pseudo element that has the inset border on it.
    // @include extra-border(before, 4px, 2px, $brown-light);
  }
}

.tab-content--inverse {
  background-color: $inverse-bg-color;
  color: $inverse-text-color;
}
