.fallback-map-container {
  height: 100%;
  overflow: hidden;
  // width: 100%;
}

.fallback-map {
  background: url(/map/fallback.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  position: relative;
  width: calc(100vh * 1879 / 1080);
}

.fallback-map__funnelling-indicator {
  cursor: pointer;
  transition: filter .2s;

  &:hover {
    filter: sepia(1) saturate(2.5) hue-rotate(3deg) drop-shadow(0 0 1px #fdf2b7);
  }
}