/**
 * GENERIC TEXT AND LINK STYLES.
 * There are very base styles set in base/_body.scss.
 *
 * By default the colors are all for light on dark.
 */

/****************************************************************************
 * Headings.
 */

// Base heading style.
.heading {
  font-family: $font-family-serif;
  font-weight: $font-weight-bold;
}

// Add one of these for different sizes/colors.

// Page title.
.heading--1 {
  font-size: $font-size-largest;
}

// Roots.
.heading--2 {
  font-size: $font-size-larger;
}

// Branches and messages.
.heading--3 {
  font-size: $font-size-large;
}

// Paragraph title.
.heading--4 {
  font-size: $font-size-base;
}

.heading--hr {
  padding-bottom: 0.25em;
  border-bottom: 2px solid #756b5a;
  margin-bottom: 1em;
}

.heading--inverse {
  color: $inverse-text-color;
}

.heading--no-margin-bottom {
  margin-bottom: 0;
}

.bastard .heading--hr {
  border-bottom: 2px solid #a89e8c;
}

// Cosy.
.heading--close {
  margin-bottom: 0;
}

/****************************************************************************
 * Other elements.
 */

p {
  margin-bottom: 0.5em;
}

.lede {
  font-family: $font-family-serif;
  font-weight: $font-weight-bold;
  font-size: $font-size-large;
}

ol {
  list-style-type: decimal;
  padding-left: 1.5em;
}

ul {
  list-style-type: disc;
}

a {
  color: $link-color;
  font-weight: $font-weight-bold;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: none;
  }

  &:focus {
    @include tab-focus;
  }
}

strong,
b {
  font-weight: $font-weight-bold;
}

em,
i {
  font-style: italic;
}

code,
kbd,
pre,
samp {
  font-size: 90%;
  font-family: $font-family-monospace;
  color: $beige-light;

  .bastard & {
    color: $brown-mid;
  }
}

hr {
  margin-top: $line-height-computed;
  margin-bottom: $line-height-computed;
  border: 0;
  border-top: 2px solid $text-color;
  border-bottom: 1px solid #2b2b2b;
}

.bastard hr {
  border-top: 2px solid $brown-light;
  border-bottom: none;
}

.bastard {
  background-color: $inverse-bg-color;
  color: $inverse-text-color;
  position: relative;

  &:before {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .heading--1,
  .heading--2,
  .heading--3,
  .heading--4 {
    color: $inverse-text-color;
  }

  a {
    color: $inverse-link-color;

    &.menu-item--active {
      color: $gray-darker;
    }

    &:hover,
    &:focus {
      color: $inverse-link-hover-color;
    }
  }

  .bastard hr {
    border-color: $brown-light;
  }
}

.link {
  color: $link-color;
  cursor: pointer;
  padding: unset;
  font-weight: 700;

  &:hover,
  &:focus {
    color: $link-hover-color;
  }
}

.link--inverse {
  color: $inverse-link-color;
  cursor: pointer;

  &.menu-item--active {
    color: $gray-darker;
  }

  &:hover,
  &:focus {
    color: $inverse-link-hover-color;
  }
}

.item-use-warning {
  display: block;
  padding-top: 0.5em;
  font-weight: bold;
  color: #C8170E;
  clear: both;
}
