.tooltip {
  align-items: flex-start;
  background: #1d1d1d none repeat scroll 0 0 !important;
  box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.5);
  border: 2px solid #3e3e3e;
  color: #efefef;
  display: flex;
  padding: .5rem;
  max-width: 300px;
}

.tooltip--item-modal {
  border: none;

  @media (min-width: $screen-xs-min) {
    display: flex;
  }
}

.tooltip__buttons {
  display: flex;
  justify-content: flex-end;
}

.tooltip__icon {
  float: left;
  margin-right: 1rem;

  @media (min-width: $screen-xs-min) {
    float: none;
  }
}

// .tooltip__available-at {
  // font-style: italic;
// }

.tooltip__desc {
  @supports (hyphens: auto) {
    hyphens: auto;
    text-align: justify;
  }
}

.tooltip__secondary-description {
  font-style: italic;

  .quality-name {
    font-weight: 500;
  }

  .unlock {
    display: block;
    margin-bottom: .5rem;
  }

  .unlock-separator {
    display: none;
  }
}

/* Misc stuff that only appears in tooltips */

.enhancements-description {
  color: #7ebcc0;
  display: block;
  font-style: normal;
}

.wordy-list {
  list-style: none;
  margin-top: 0.5em !important;
}

.wordy-list li {
  margin-left: .5rem;
}

.wordy-list .current {
  font-weight: 500;
}

.wordy-list li.current::after {
  content: " \f00c";
  font-family: 'FontAwesome';
  font-weight: normal;
}
