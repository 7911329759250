.credits,
.help,
.privacy-policy,
.terms {
  line-height: 1.5;
  max-width: 40rem;
  margin-left: auto;
  margin-right: auto;
  font-size: 110%;
  // text-align: justify;

  h1 {
    color: #fff;
  }

  h2 {
    margin-top: .5em;
  }

  p {
    margin-top: 0.5em;
  }

  ul {
    margin-left: 2rem;
  }
}

.credits {
  font-family: $font-family-serif;
  // margin-top: 4rem;
}

.credits__navigation {
  margin-bottom: 4rem;
}

.credits .cms-page {
  text-align: center;
}

.help {
  max-width: 50rem;
}

.privacy-policy {
  counter-reset: section;

  ol {
    list-style-type: none;

    & > li {
      margin-bottom: 1rem;

      &::before {
        counter-increment: section;
        content: counters(section, ".") ". ";
        font-size: 110%;
        font-weight: bold;
      }
    }
  }
}

.terms {
  counter-reset: section;

  h2 {
    &::before {
      counter-increment: section;
      content: counters(section, ".") ". ";
    }
  }
}
