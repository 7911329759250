@import 'cross-fade';
@import 'fade-wait';

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.fade-appear {
  opacity: 0;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: all .5s ease-in;
}

.fadeIn-enter {
  opacity: 0;
  transition: 0.3s ease-in-out all;
  transform: translate3d(0, .2vh, 0);
}

.fadeIn-enter.fadeIn-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
