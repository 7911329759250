.journal-entries-container {
  margin: 0;
  padding: 0 1rem;
  position: relative;
}

.journal-entries__controls {
  align-items: center;
  display: flex;
  justify-content: center;
}

.journal-entries__control {
  font-size: 125%;
  margin-left: 1rem;
  margin-right: 1rem;
}

.journal-entries__control--disabled {
  color: grey;
  pointer-events: none;
}

.journal-entries__header {
  color: #efefef;
  margin-bottom: 0;
  margin-right: 1rem;
}

.journal-entries__header-and-controls {
  align-items: center;
  display: flex;
  margin-bottom: 0.5rem;
}

.journal-entry {
  background-color: #bdb29e;
  background-repeat: repeat;
  background-position: 0 0;
  box-shadow: 0 2px 3px 0 #a89e8c;
  padding: 10px 16px 16px 16px;
  position: relative;
  z-index: 0;
  color: #2b2b2b;
}

.journal-entry:before {
  content: " ";
  position: absolute;
  z-index: -1;
  top: 2px;
  right: 3px;
  bottom: 3px;
  left: 3px;
  border: 2px solid #a89e8c;
}

.journal-entry--is-favourite:before {
  content: " ";
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: -2px;
  left: 0;
  border: 4px solid $blue-dark;
}

.journal-entry--is-fetching {
  opacity: 0.5;
}

.journal-entry__body {
}

.journal-entry__body ul {
  list-style-position: inside;
  margin-bottom: 10px;
}

.journal-entry__buttonlet {
  float: right;

  * {
    vertical-align: middle;
  }
}

.journal-entry__date {
  margin-right: 1ex;
}

.journal-entry__date-and-location {
  display: flex;
  flex-wrap: wrap;
}

.journal-entry__location {
  display: flex;
}

.journal-entry__permalink {
  margin: 0.15rem 0.5rem 0;
}

.journal-entry__title {
  margin-bottom: 0;
}

.journal-entry--favourite-container {
  display: inline;
}

.journal-entry--favourite-image {
  margin: 0 0.5rem 0.5rem 0;
}

.journal-entry--unfavourite-image {
  margin: 0 0.5rem 0.25rem 0;
}
