.profile-card--xs {
  display: flex;
  flex-direction: column;
}

.profile-card__column-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @media (min-width: $screen-xs-min) {
    flex-direction: row;
  }
}

.profile-card__header--xs {
  width: 100%;
}

.profile-card__left-col {
  align-items: center;
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 0.5rem;

  @media (min-width: $screen-xs-min) {
    max-width: 148px;
    width: 50%;
  }
}

.profile-card__right-col {
  @include extra-border(before, (3px, 3px, 3px, 3px), 1px, $brown-light);

  background-color: $brown-lightest;
  background-repeat: no-repeat;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem;

  @media (min-width: $screen-xs-min) {
    width: 50%;
  }
}

.profile-card__scrapbook {
  @media (max-width: $screen-sm-down) {
    margin-top: 1rem;
  }
}

.profile-card__view-profile-button {
  width: 100%;

  @media (min-width: $screen-xs-min) {
    width: auto;
  }
}

.profile-card__view-profile-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  @media (min-width: $screen-xs-min) {
    align-items: flex-end;
    flex-direction: column;
  }

  @media (min-width: $screen-sm-up) {
    flex-direction: row;
    justify-content: flex-end;
  }
}
