.signup-avatars {
  display: flex;
  justify-content: space-between;
  margin-left: -1rem;
  margin-right: -1rem;
  flex-wrap: wrap;
}

.signup-avatar {
  margin: 1rem;
  &:hover {
    transform: scale(1.05);
  }
}

/**
 * Used on the avatars on the signup page.
 */
.img-selectable {
  border: 6px solid transparent;
  cursor: pointer;
  transition: border-color 150ms;
}

.img-selected {
  border: 6px solid #756b5a;
}

.avatar {
  z-index: 2;
  position: relative;
}