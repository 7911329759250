.premium-sub-purchase-card {
  display: flex;
  margin-bottom: 1rem;
}

.premium-sub-purchase-card__body {
  background-color: $brown-lightest;
  background-repeat: no-repeat;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 1rem;
}

.premium-sub-purchase-card__border {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 13px;

  & > div {
    position: relative;

    &:after {
      bottom: 0;
      box-shadow: 0 0 2px black;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.premium-sub-purchase-card__description {
  font-size: 110%;
}

.premium-sub-purchase-card__image {
  border-radius: 2px;
  display: block;
  width: 140px;
}

.premium-sub-purchase-card__left {
  margin-right: 1rem;
}

.premium-sub-purchase-card__title {
  margin-bottom: 1rem;
}
