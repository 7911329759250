.footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: $screen-lg-up) and (max-width: $screen-lg-down) {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    @media (min-width: $screen-xl-up) {
        max-width: 750px;
    }
}

.footer-content--login-page {
    @media (max-width: $login-page-breakpoint) {
        margin-left: -2rem;
        margin-right: -2rem;
    }
}
