.available-item-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(48px, 1fr));
  grid-auto-rows: 48px;
  list-style: none;
  margin-top: 10px;
}

.available-item-list__item {
  margin-left: auto;
}

// Sorry... We need to jump to the grandchild because of the extra element introduced
// by react-interactive
.available-item-list__item > div > div:focus
{
  outline: solid 2px #3f7277 !important;
  outline-offset: 2px;
  // transform: scale(1.111111111);
}
