.enforced_break {
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.plans_margin {
  margin: '36px 0 24px';
}

.plans_separator {
  position: relative;
}

.plan__buttons {
    // On xs devices, float the buttons to the right so that the header
    // wraps around them
    float: right;
    // On larger devices, pin them to the top right of the plan
    // TODO: this may not be necessary; floating may be fine on
    // sm screens and above
    @media (min-width: $screen-sm-min) {
        float: none;
        position: absolute;
        right: 8px;
        top: 8px;
    }
}