.leaflet-control--custom-zoom {
  cursor: pointer;
  display: block;
  transition: filter .2s;

  &:first-child {
    margin-bottom: .5rem;
    margin-top: .5rem;
  }

  &:hover {
    // TODO(sdob) Should we have a hover effect on the zoom buttons?
    // filter: sepia(1) saturate(2.5) hue-rotate(3deg) drop-shadow(0 0 1px #fdf2b7);
  }
}

.leaflet-control--custom-zoom--disabled {
  filter: brightness(0.7);
}
