.purchase-outfit-slot-modal__content {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.purchase-outfit-slot-modal__header {
  @extend .heading;
  @extend .heading--2;

  border-bottom: solid 1px black;
  margin-bottom: 16px;
  padding-bottom: 8px;
  text-align: center;
  width: 100%;
}

.purchase-outfit-slot-modal__explanation {
  font-family: $font-family-serif;
  font-style: italic;
  margin-left: .5em;
}

.purchase-outfit-slot-modal__not-enough-fate-rubric {
  border-top: solid 1px black;
  margin-top: 16px;
  padding-top: 8px;
  text-align: center;
  width: 100%;
}

.purchase-outfit-slot-modal__rubric {
  font-family: $font-family-serif;
  font-weight: bold;
  margin-right: .5em;
}

.purchase-outfit-slot-modal__value {
  font-family: $font-family-serif;
  font-weight: bold;
}
