/**
 * VISIBILITY
 * Things affecting the visibility in browser or screen readers.
 */

/*
 * Hide only visually, but have it available for screen readers:
 */
.u-visually-hidden {
    @include visually-hidden;
}

/*
 * Use with the .visually-hidden class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 * eg, for 'Skip to main content' links.
 */
.u-focusable:active,
.u-focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

