.fate-card {
  display: flex;
}

.fate-card:first-of-type {
  margin-top: 0;
}

.fate-card__body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.fate-card__border {
  & > div {
    padding: 8px 6px 6px;
    position: relative;

    &:after {
      bottom: 0;
      box-shadow: 0 0 2px black;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.fate-card__attribution {
  // display: inline;
}

.fate-card__bordered-container {
  display: flex;
}

.fate-card__bordered-container--story {
  @media (max-width: $screen-sm-down) {
    flex-direction: column;
  }
}

.fate-card__image {
  height: 100px;
  width: 78px;
}

.fate-card__image-container {
  @media (max-width: $screen-sm-down) {
    margin-right: .5rem;
  }
}

.fate-card__left {
  // display: flex;
  margin-right: .5rem;
}

.fate-card__left--story {
  @media (max-width: $screen-sm-down) {
    align-items: flex-start;
    display: flex;
    margin-bottom: .5rem;
    margin-right: 0;
  }
}

.fate-card__title--force-breaks {
  display: flex;
  flex-direction: column;
}

.fate-card__title--story {
  @media (max-width: $screen-sm-down) {
    // align-items: baseline;
    display: flex;
    flex-direction: column;
  }
}

.fate-card__title-and-byline--story {
  @media (max-width: $screen-sm-down) {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}

.enhanced-store--ribbon-spacer {
  padding-top: 1rem;
}
