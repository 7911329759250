/***************************************************************************
 * Z-INDEXES.
 */

$z-index-max: 2147483647;

// $z-index-sidemenu:			($z-index-max - 2);
// $z-index-sidemenu-overlay:	($z-index-max - 3);
$z-index-footer-xs: 450;
$z-index-content-overlay: $z-index-max - 5;
$z-index-tooltip: $z-index-max - 1;
$z-index-blackout: $z-index-max;
$z-index-modal: 200;
$z-index-toast: 150;
$z-index-panel: 100;
$z-index-modal-background: 10;
$z-index-content: 1;
$z-index-banner-xs: -1;
$z-index-banner-map: 1;
$z-index-banner-fixed: 2;
$z-index-modal-dialog-overlay: 499;
$z-index-sidemenu: 498;
$z-index-sidemenu-overlay: 497;
$z-index-map-modal-overlay: 500;
$z-index-gate-storylet-modal-overlay: 502;
$z-index-responsive-tooltip-overlay: 504;
$z-index-map-action-refresh-overlay: 505;
$z-index-map-exceptional-friend-overlay: 506;
$z-index-gate-storylet-share-dialog-overlay: 507;
