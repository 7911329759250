/**
 * HEADER
 * The illustration across the top of the page.
 */

// Shown up to 767px wide.
.header-xs {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-index-banner-xs;
  width: 100%;
  height: $header-xs-height;
  padding: 15px 10px 0 10px;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.5);

  @media (min-width: $screen-xs-min) {
    // We can have a different image from 480px wide up:
  }

  @media (min-width: $screen-sm-min) {
    display: none;
  }
}

// Shown from 768px wide upwards.
.header-sm {
  display: none;

  @media (min-width: $screen-sm-min) {
    display: block;
    width: 100%;
    height: 200px;
  }
}

/**********************************************************************
 * These nav elements are only used within .header-xs:
 */
.header-xs {
  ul {
    display: table;
    width: 100%;
  }
}

.header__fullscreen-toggle {
  background: transparent;
  border: none;
  color: $form-control-color;
  cursor: pointer;
  margin-right: 1rem;
  padding: 0;
}

.header__logout-button {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 100%;
}

.header__item {
  display: table-cell;
  width: 33%;

  a {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 44px;
    height: 44px;
    text-align: center;
    color: $header-button-color;

    &:hover,
    &:active {
      color: $header-button-active-color;
    }
  }

  &:first-child a {
    margin-left: 0;
  }

  &:last-child a {
    margin-right: 0;
  }
}
