.opportunity-cards--small {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
}

.opportunity-cards__body--small {
    flex: 1;
}

.opportunity-cards__left--small {
    margin-right: .5rem;
}

.opportunity-deck--small {
    margin-right: 10px;
}