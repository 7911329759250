.cms-page {
  h1 {
    @extend .heading;
    @extend .heading--1;
  }

  h2 {
    @extend .heading;
    @extend .heading--2;
  }

  ul {
    list-style: none;
  }

  li {
    margin: .5rem 0;

    p {
      margin: .5rem 0;
    }
  }
}