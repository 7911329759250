.error-boundary {
  align-items: center;
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
}

.error-boundary__content {
  max-width: 30rem;
  padding: 1rem;
  text-align: justify;
}

.error-boundary__p--link {
  text-align: center;
}