/**
 * For the large CAMEO image.
 *
 * <div class="cameo">
 *	<img src="...">
 * </div>
 *
 */
.cameo
{
  display: inline-block;
  margin-bottom: 0.5em;
  position: relative;
  z-index: 0;
}


.cameo:before
{
    content: " ";
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 1px solid rgba(255, 255, 255, 0.5);
  }

  .cameo img
  {
    display: block;
    max-width: 100%;
    height: auto;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.6);
  }
