.fade-wait-exit {
  opacity: 1;
  /*transform: translate3d(0, 0, 0);*/
}
.fade-wait-exit.fade-wait-exit-active {
  opacity: 0;
  transition: all .4s ease-in;
  /*transform: translate3d(0, 4vh, 0);*/
}

.fade-wait-enter {
  opacity: 0;
  /*transform: translate3d(0, 1vh, 0);*/
}
.fade-wait-enter.fade-wait-enter-active {
  opacity: 1;
  /* Delay the enter animation until the exit completes */
  transition: all .4s ease-in .6s;
  /*transform: translate3d(0, 0, 0);*/
}

.fade-wait-height {
  transition: height .6s ease-in-out;
}