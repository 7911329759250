/**
 * Sidemenu - off-canvas menu.
 * Based on https://github.com/christophery/pushy/ v0.9.2.
 *
 * <div class="js-sidemenu-content sidemenu-content">
 *		<!-- Main page content -->
 * </div>
 *
 * <div class="js-sidemenu-overlay sidemenu-overlay"></div>
 *
 * <div class="js-sidemenu sidemenu sidemenu--left">
 *		<!-- menu content -->
 * </div>
 *
 * <nav class="js-sidemenu-header">
 *		<!-- Optional thing that's on page, as well as content. -->
 *		<!-- Perhaps position:fixed -->
 * </nav>
 *
 * <nav class="js-sidemenu-footer">
 *		<!-- Optional thing that's on page, as well as content. -->
 *		<!-- Perhaps position:fixed -->
 * </nav>
 */

// There are some styles related to overflow and scrolling in base/_body.scss
// too.

/***********************************************************************
 * The menu itself.
 */
.sidemenu {
    background: $bg-color;
    box-shadow: inset -10px 0 6px -9px rgba(0, 0, 0, 0.7);
    color: #efefef;
    height: 100%;
    max-width: 80vw;
    overflow: auto;
    padding: 0 15px;
    position: fixed;
    top: 0;
    transition: transform 0.2s cubic-bezier(0.16, 0.68, 0.43, 0.99);
    width: $sidemenu-width;
    z-index: $z-index-sidemenu;

    // Enables momentum scrolling in iOS overflow elements:
    -webkit-overflow-scrolling: touch;
}

// As well as .sidemenu, to indicate that it is offscreen/closed.
.sidemenu--left {
    @include translate3d(-$sidemenu-width);
}

// Applied to .sidemenu when onscreen/open.
.sidemenu--open {
    @include translate3d;
}

// Things within the sidemenu.

.sidemenu__nav {
    margin-top: 15px;
    margin-bottom: 15px;
    border-top: 1px solid $black;
    border-bottom: 1px solid $gray-darker;
    margin-bottom: .5rem;
}

.sidemenu__nav-item {
    border-top: 1px solid $gray-darker;
    border-bottom: 1px solid $black;
}

.sidemenu__nav-link {
    display: block;
    padding: 1rem .5rem 1rem 0;
    width: 100%;

    &:hover,
    &:active {
        background: $link-color;
        color: $gray-darkish;
    }
}

.sidemenu__nav-icon {
    color: $gray-lightest;
    padding-right: 10px;
}

.sidemenu__nav-icon--fl-ico {
    background-color: $gray-lightest;
    margin-right: 5px;
    padding-right: 0;
}

.sidemenu__version-number {
    text-align: center;
    margin-bottom: 0.5rem;
}

/***********************************************************************
 * Containing the main page content.
 * The header and footer are in components/ header.scss and footer.scss.
 */

.sidemenu-content {
    width: 100%;
    // margin-top: $banner-xs-height;
    margin-bottom: $footer-xs-margin;

    @media (min-width: $screen-sm-min) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

// TODO: Can this be removed safely? I can't find any other occurrences.
.fixednav-content {
    width: 100%;
    margin-top: $banner-fixed-height;

    @media (min-width: $screen-sm-min) {
        margin-top: 0;
        margin-bottom: 0;
    }

    & .banner {
        z-index: $z-index-banner-fixed;
    }
}

/***********************************************************************
 * Menu movement.
 */

/* Site Overlay */

.sidemenu-overlay {
    opacity: 0;
    z-index: -10;
}

.sidemenu-overlay--open {
    background-color: rgba(0, 0, 0, 0.5);
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
    transition: opacity 500ms;
    z-index: $z-index-sidemenu-overlay;
}
