.account {
  margin: 0 auto;
  max-width: 50rem;
}

.account__add-contact-form {
  align-items: center;
  display: flex;
}

.account__contact {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: .5rem;
  padding: .25rem .5rem;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

.metaqualities__list {
  margin: 24px 0;
  padding: 0;
}

.metaqualities__quality {
  list-style: none;
  margin: 0 0 12px;

  &::after {
    display: block;
    clear: both;
    content: '';
  }
}

.metaqualities__image {
  float: left;
  max-width: 100px;
}

.metaqualities__title {
  font-weight: bold;
}

.metaqualities__body {
  float: left;
  padding: 0 16px;
  max-width: calc(100% - 100px);
  width: 100%;
}

.list-icons {
  list-style: none;
}

.back-button {
  margin-top: 10px;
  cursor: pointer;
}

#user-friends-name {
  margin-bottom: 10px;
}