// Don't give me grief about these --- they directly override Braintree styles
.braintree-method {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}

.braintree-dropin {
  font-family: $font-family-sans-serif;
}

.braintree-methods--active:not(.braintree-methods--edit) .braintree-method--active {
  border-radius: 0;
}

[data-braintree-id="choose-a-way-to-pay"] {
  display: none;
}

[data-braintree-id="methods-label"] {
  float: left;
}

.braintree-form__field-group label {
  display: inline;
}

// Jesus, Braintree, we *get* it, you like border-radius
.braintree-upper-container,
.braintree-upper-container:before,
.braintree-method:first-child,
.braintree-method:last-child,
.braintree-method:only-child,
.braintree-option:first-child,
.braintree-option:last-child,
.braintree-option:only-child,
.braintree-large-button,
.braintree-sheet,
.braintree-icon--bordered {
  border-radius: 0;
}

/* Braintree just hides the methods while we're submiting, but we want to take them
 * out of the flow entirely
 */
.braintree-dropin-wrapper.submitting {
  [data-braintree-id="methods-container"] {
    display: none;
  }
}
