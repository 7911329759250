/**
 * BUTTONS
 * Based on Bootstrap's buttons.
 * http://getbootstrap.com/css/#buttons
 *
 * The .button class should be added to buttons, but can also be added to
 * anchors to make them look like buttons. eg:
 *
 * <button class="button button--primary">Go</button>
 *
 * <a href="#" class="button button--primary">Go</a>
 *
 * Could have some 'more' text:
 *
 * <a href="#" class="button button--primary">
 *	Go <span class="button__more">(7)</span>
 * </a>
 */

/*****************************************************************************
 * Base styles
 */

$button-padding-x: 20px;
$button-padding-y: 10px;

.button {
  display: inline-block;
  margin-left: 4px;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid transparent;
  box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-size: $font-size-button;
  font-weight: $font-weight-bold;
  font-family: $font-family-serif;
  padding: $button-padding-y $button-padding-x;

  // Don't go into copy-and-paste mode
  user-select: none;

  // NOOOOO
  // @include button-size(10px, 10px, 20px, $font-size-base, $line-height-base);

  // A pseudo element before the button which we can then apply a border to.
  // This will be the lighter border on the button's inside edge.
  @include extra-border(before, 0);

  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include tab-focus;
    }

    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  }

  &:hover,
  &:focus,
  &.focus {
    color: #000;
    text-decoration: none;
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }

  &.button--disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: not-allowed;

    // This interferes with tooltips etc:
    //pointer-events: none; // Future-proof disabling of clicks
    opacity: .5;
    box-shadow: none;

    &,
    &:hover,
    &:focus,
    &:active {
      background-color: $button-disabled-bg;
      border-color: darken($button-disabled-bg, 10%);
      color: $button-disabled-color !important;

      &:before {
        border: 1px solid lighten($button-disabled-bg, 10%);
      }
    }

    .button__more {
      color: $button-disabled-color-more;
    }
  }

  &:only-child {
    margin-left: 0;
  }
}

/************************************************************************
 * Button variations.
 * Use in addition to .button.
 */

.button--no-margin {
  margin: 0;
}

.button--primary {
  @include button-variant($button-primary-color, $button-primary-bg, $button-primary-border, $button-primary-color-more);
}

.button--secondary {
  @include button-variant($button-secondary-color, $button-secondary-bg, $button-secondary-border, $button-secondary-color-more);
}

.button--tertiary {
  @include button-variant($button-tertiary-color, $button-tertiary-bg, $button-tertiary-border, $button-tertiary-color-more);

  box-shadow: none;
}

.button--dangerous {
  @include button-variant($button-dangerous-color, $button-dangerous-bg, $button-dangerous-border, $button-dangerous-color-more);
}

// 'Go' (or similar) button in storylet branch
// .button--go {
  // margin-left: 8px;
// }

/************************************************************************
 * Button Sizes
 * Use in addition to .button.
 */

// TODO: Deprecate this
.button--sm {
  padding: 5px 15px 5px 15px;
  font-size: 13px;
  line-height: 1.38462;
}

.button--small {
  font-size: 80%;
  padding: calc(#{$button-padding-y} * .8) calc(#{$button-padding-x} * .8);
}

// Buttons that look like links
.button--link {
  background: transparent;
  border: none;
  color: $link-color;
  cursor: pointer;
  font-size: unset; // TODO: worry about this later
  font-weight: $font-weight-bold;
  padding: 0;

  &:hover {
    color: $inverse-link-hover-color;
    text-decoration: none;
  }

  &:focus {
    color: $inverse-link-hover-color;
  }
}

.button--link-dangerous {
  color: $button-dangerous-color;
  &:hover, &:focus {
    color: $button-dangerous-bg;
  }
}

.button--link-inverse {
  color: $inverse-link-color;
}

.button--menlo-park-panopticon,
.button--google {
  margin-left: 0;
  white-space: nowrap;
  width: 100%;

  & > span {
    align-items: center;
    display: flex;

    // justify-content: space-between;
    overflow: hidden;

    & > i,
    & > img,
    & > svg {
      margin-right: .5rem;
      width: 26px;
    }
  }
}

.button--menlo-park-panopticon {
  align-items: center;
  display: flex;
}

.button--menlo-park-panopticon i {
  color: rgb(59, 89, 152);
  margin-right: .5rem;
}

.button--google img {
  height: 26px;
}

.item__controls {
  float: right;
}
