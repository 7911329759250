.create-character {
  margin-left: auto;
  margin-right: auto;
  max-width: 40rem;
}

.create-character__initial-rubric {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}