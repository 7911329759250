.messages {
  display: flex;
  @media (min-width: $screen-md-up) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.messages__heading {
  margin-bottom: .5rem;
}