* {
    // Make the box model all modern, like.
    box-sizing: border-box;
}

body {
    // These are the 'base' colors for the page, which are actually the colors
    // of the middle beige panel, because that has most of the actual content
    // in.
    background-color: $bg-color;
    color: $text-color;

    // Most common paragraph sizes/styles. Variations in base/_typography.scss.
    font-size: $font-size-base;
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-base;
    line-height: $line-height-base;
}

a {
    cursor: pointer;
}
