/**
 * STRIPE
 * Across the very top of the page, containing navigation, at wide widths.
 */
.top-stripe {
    display: none;
    background-color: #222;
    border-bottom: 1px solid #303030;
    justify-content: space-between;
    width: 100%;

    @media (min-width: 768px) {
        display: flex;
        background-position: 1rem center;
        background-size: 240px;
        background-repeat: no-repeat;
    }
}

.top-stripe__inner-container {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: space-between;
    max-width: 1180px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
}

// noinspection CssUnknownTarget
.top-stripe__site-title {
    min-height: 1px;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 4px;
    width: calc(350px * 4/5);
    height: calc(40px * 4/5);
    // margin-left: 8px;
    margin-top: 4px;
}

.top-stripe__user {
    align-items: center;
    display: flex;
    position: relative;
    padding-left: 5px;
    padding-right: 5px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 13px;
    text-align: right;
}

.top-stripe__user-nav {
    display: flex;
    list-style: none;
}

.top-stripe__user-name {
    margin-right: .5rem;
}
