// Background Image
.container-background-image {
  background-color: black;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  overflow-y: auto;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;

  &::after {
    content: '';

    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    position: fixed;
    height: 90vh;
    left: 0;
    top: 0;
    width: 90vw;
    z-index: 1;

    @media (min-width: $screen-sm-up) {
      height: 80vh;
      width: 80vw;
    }

    @media (min-width: $screen-md-up) {
      height: 70vh;
      width: 70vw;
    }
  }
}

.login {
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: auto auto 1fr;
  height: calc(100vh - 2rem); // 100vh - margin-top

  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  max-width: calc(#{$screen-xl-up / 2} - 2rem);
  z-index: 2;

  @media (min-width: ($login-page-breakpoint + 1)) {
    margin-left: auto;
    margin-right: 2rem;
  }

  @media (min-width: ($login-page-breakpoint + 1)) {
    margin-left: 0;
    position: relative;
    left: calc(50% - 2rem);
  }

  @media (max-width: $login-page-breakpoint) {
    padding: 2rem;
    position: relative;
    top: 40vh;
  }

  & > :last-child {
    align-self: self-end;
    margin-bottom: 2rem;
  }
}

.login__copy-and-form {
  display: flex;
  text-align: justify;
  hyphens: auto;

  @media (max-width: $login-page-breakpoint) {
    flex-direction: column;
  }
}

.login__copy {
  color: #efefef;
  padding-right: 2rem;

  @media (max-width: $screen-md-down) {
    padding-right: 0;
  }
}

.login__overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  pointer-events: none;
  transition: opacity 200ms ease-in-out;
  z-index: 2;
}

.login__remember-me {
  margin-right: 1rem;
}

.login__remember-me-and-submit {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.password-field {
  display: grid;
  grid-template-columns: 1fr auto auto; // some extensions inject a div btw the field and the disclosure

  & > input {
    border-right: none;
  }

  & > i {
    border-left: none;
  }
}

.password-field:focus-within {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(126, 188, 192, 0.6);

  & > input {
    box-shadow: none;
  }

  & > i {
    border-color: $blue-lightish;
  }
}

.login__form {
  @media (min-width: $login-page-breakpoint) {
    width: 275px;
  }
}

.login__tabs {
  display: grid;
  white-space: nowrap;

  // adjust first repeat() param to match the number of tabs before the gap
  grid-template-columns: repeat(2, auto) 1fr auto;
}

.login-promo-tab {
  & > div {
    padding-left: 10px;
    width: 63px;

    & > button {
      background-image: URL('/img/bat-btn-basic.svg');
      width: 43px;

      &:hover {
        background-image: URL('/img/bat-btn-hover.svg');
      }

      &.active {
        background-image: URL('/img/bat-btn-selected.svg');
      }
    }
  }
}
