/**
 * CARDS and DECKS
 */

.cards {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.card {
    display: block;
    margin: 0 7.7px 0 5px;
    transition: opacity 200ms;
}

$empty-card-height: 125px;
$empty-card-width: 96px;

$lg-height: 132.6px;
$lg-width: 102px;

.card--fetching {
    cursor: default;
    opacity: 0.5;
}

.card--lg {
    background-size: 700% 100%;
    border-radius: 2px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.8);
    width: $lg-width;
    height: $lg-height;
}

.card--lg img {
    border-radius: 2px;
}

.card--lg::before {
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.6);
    content: '';
    display: block;
    z-index: -1;
    width: $lg-width;
    height: $lg-height;
    position: absolute;
    border-radius: 2px;
}

.card--sm {
    position: relative;
    z-index: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
}

.card--sm:before {
    content: " ";
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.card--sm:before {
    z-index: 0;
}

.card--lg--cover {
    cursor: pointer;
    position: absolute;
    width: $lg-width;
    height: $lg-height;
    background: $brown-dark;
    line-height: 1.5em;
    padding: 10px;
    color: $white;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
}

.card--empty {
    margin: 0 7.7px 0 5px;
    width: $empty-card-width;
    height: $empty-card-height;
    background: rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.5);
    cursor: default;
}

/****************************************************************************
 * Different card types.
 */

.card-gold {
    background-position: -102px 0;
}

.card-silver {
    background-position: -204px 0;
}

.card-bronze {
    background-position: -306px 0;
}

.card-black {
    background-position: -408px 0;
}

.card-red {
    background-position: -519px 0;
}

.card-purple {
    background-position: -612px 0;
}

// Classes only done for large cards.
.card--lg {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.8);
    text-align: center;
}

/****************************************************************************
 * A hand of crrds.
 * Wrap .hand around several .card's.
 */

.hand {
    display: flex;
    flex: 1;
    justify-content: flex-start;
}

.card__discard-button {
    margin-left: 0;
    margin-top: .5rem;
    width: 100%;
}

.hand--small-media {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.card-cover {
    width: 100%;
    display: none;
}

.card-cover:hover {
    display: block;
}

.card--lg img {
    display: inline-block;
    margin: 6px;
}

/*
NEW CARD BORDER
*/

.card-border {
    background-size: 700% 100%;
    text-align: center;
}

.card-border img {
    // vertical-align: middle;
    // padding: 7%;
}

.card-gold {
    background-position: 16.6% 0;
}

.card-silver {
    background-position: 33.3% 0;
}

.card-bronze {
    background-position: 50% 0;
}

.card-black {
    background-position: 66.7% 0;
}

.card-red {
    background-position: 83.4% 0;
}

.card-purple {
    background-position: 100% 0;
}

