
/**
 * Add a second (or third) border to an element with a pseudo class.
 *
 * The element you're adding this to should have position:relative.
 *
 * eg:
 * .test {
 *		@include extra-border(before, 0, 1px, rgba(255,255,255,0.5));
 * }
 *
 * or:
 * .test {
 *		@include extra-border(before, (2px,3px,3px,3px), 2px, $brand-color);
 * }
 *
 * or, with no border, then add borders manually:
 * .test {
 *		@include extra-border(before, 0);
 * }
 * .test.variant {
 *		&:before {
 *			border: 1px solid red;
 *		}
 * }
 *
 * $direction is either `before` or `after`.
 * $position is a position for the element, either a string or list.
	If a list, it has four elements for top, right, bottom, left position.
 *	eg, `0` will make an element that's the same size as the parent. A border
 *		on this will thus appear inside the parent, because we're using
 *		box-sizing:border-box.
 *	or, `-3px` will make an element that's 3px bigger (in each direction) than
 *		the parent.
 *	or, `(2px,3px,3px,3px)` will be 3px inside the parent element, except the
 *		top which is 2px in.
 * $border-width (optional) is the width of the border, eg 1px
 * $border-color (optional) is eg `rgba(255,255,255,0.5)`, or '#fff',
 *		or $brand-color.
 */
@mixin extra-border($direction, $position, $border-width:'', $border-color:'') {

	position: relative;
	z-index: 0;

	&:#{$direction} {
		content: " ";
		position: absolute;
		z-index: -1; // Or else things within the parent element aren't clickable.
		@if type-of($position) == list {
			top:	nth($position, 1);
			right:	nth($position, 2);
			bottom: nth($position, 3);
			left:	nth($position, 4);
		} @else {
			top:	$position;
			right:	$position;
			bottom: $position;
			left:	$position;
		}
		@if $border-width != "" {
			border: $border-width solid $border-color;
		}
	}
}

