$action-candle-transition-duration: .5s;
$action-candle-transition-easing: ease-in-out;
.candle-container {
  align-items: flex-end;
  display: flex;
  justify-content: center;
  position: absolute;
  transition: top $action-candle-transition-duration $action-candle-transition-easing;
  width: 100%;
}

.candle-container div {
  transition: height $action-candle-transition-duration $action-candle-transition-easing;
}