/**
 * Button mixins.
 * For making variants of buttons.
 * Originally from Bootstrap
 * https://github.com/twbs/bootstrap-sass/blob/master/assets/stylesheets/bootstrap/mixins/_buttons.scss
 */

/** Easily pump out default styles, as well as :hover, :focus, :active,
 * and disabled options for all buttons
 */
@mixin button-variant($color, $background, $border, $more_color) {
  color: $color;
  background-color: $background;
  border-color: darken($border, 10%);

  // For a.button when in an .bastard panel.
  .bastard & {
    color: $color;
  }

  // The pseudo-element that provides the lighter "inside" border.
  &:before {
    border: 1px solid lighten($background, 15%);
  }

  // Get lighter for hover/focus.
  &:not([disabled]):hover,
  &:not([disabled]):focus,
  .bastard &:not([disabled]):hover,
  .bastard &:not([disabled]):focus {
    color: $color;
    background-color: lighten($background, 8%);
    border-color: $border;

    &:before {
      border: 1px solid lighten($background, 20%);
    }
  }

  // Get darker when clicked.
  &:not([disabled]):active,
  .bastard &:not([disabled]):active {
    background-image: none;
    background-color: darken($background, 5%);
    border-color: darken($border, 10%);

    &:before {
      border: 1px solid lighten($background, 5%);
    }
  }

  .button__more {
    color: $more_color;
  }
}

// Button sizes
@mixin button-size($padding-top, $padding-bottom, $padding-horizontal, $font-size, $line-height) {
  padding: $padding-top $padding-horizontal $padding-bottom $padding-horizontal;
  font-size: $font-size;
  line-height: $line-height;
}
