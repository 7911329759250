.quality-picker {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: 75vh;
  overflow-y: auto;
  padding: 1rem;
  width: 100%;
}

.quality-picker__item {
  margin: 4px;
  max-width: 50px;
  max-height: 50px;

  & img {
    height: 46px;
    width: 46px;
  }

  &:hover {
    transform: scale(1.05);

    img {
      image-rendering: pixelated;
    }
  }
}

.quality-picker__items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 1rem;

  &::after {
    content: '';
    display: block;
    flex: 10;
  }

  @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat(auto-fill, 60px);
    grid-template-rows: repeat(auto-fit, 60px);
    width: 100%;
  }
}
