.storylet {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: .5rem;
  position: relative;

  &::before {
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: -1;
  }

  // Clearfix
  &::after {
    clear: both;
    content: '';
    display: block;
  }

  @media (min-width: 480px) {
    display: flex;
    align-items: stretch;
  }
}

.persistent {
  @extend .storylet;

  background-color: #d0d4d5;
  margin-bottom: 1rem;
}

.storylet--alert--badge {
  position: absolute;
  scale: 70%;
  left: -6px;
  top: -8px;
}

.storylet--ribbon--badge {
  position: absolute;
  scale: 75%;
  left: -60px;
  top: -20px;
}

.storylet--semi-transparent {
  opacity: 0.5;
}

.storylet__body {
  // Content injected from the CMS
  & > p {
    text-align: justify;

    @supports (hyphens: auto) {
      hyphens: auto;
    }
  }

  // Above 480px, flex us out
  @media (min-width: $screen-sm-up) {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
  }
}

.storylet__bordered-container {
  width: 100%;
}

.storylet__buttons {
  clear: none;
  margin-top: 0;
}

.storylet__buttons--force-clear {
  clear: both;
  padding-top: .5rem;
}

.storylet__card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin: 0;
}

.storylet__heading {
  margin-bottom: 0;
}

.storylet__left {
  float: left;
  margin-right: .5rem;
}
