.equipment-group {
  margin: 0;
}

.equipment-group__name {
  background-color: $beige-mid-light;
  margin-bottom: 8px;
  padding: 4px 10px;
  font-size: 16px;
}

.equipment-group__slot-and-available-items {
  display: grid;
  grid-template-columns: 80px 1fr;
}

.equipment-group__available-item-list {
  list-style: none;
}

.equipment-group__equipment-slot-container {
  background-color: $beige-mid-light;
  box-shadow: 0 1px 1px 0 $gray-mid;
  height: 64px;
  margin-bottom: 8px;
  position: relative;
  width: 64px;
}

.equipment-group__equipment-slot-container--empty {
  border: solid 1px $beige-light;
}

.equipment-group__equipment-slot-container--full {
  @include extra-border(before, (3px, 3px, 3px, 3px), 1px, $brown-light);
  padding: 10px;
}

// Focus rect for the focused div
.equipment-group__equipment-slot-container--full > div > div:focus {
  outline: solid 2px rgb(29, 29, 29) !important;
  outline-offset: 2px;
}

.equipment-group__equipment-slot-container--locked {
  background-color: transparent;
  box-shadow: none;
}

.equipment-group__equipment-slot-container--unchangeable {
  background-color: transparent;
  box-shadow: none;
}
