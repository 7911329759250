/**
 * BUTTONLETS
 * The circular things made up of Font Awesome icons.
 *
 * eg:
 *
 * <a class='buttonlet buttonlet--close fa-stack fa-lg' title='Close'>
 *   <i class='fa fa-circle fa-stack-2x'></i>
 *   <i class='fa fa-times fa-stack-1x fa-inverse'></i>
 *   <span class='u-visually-hidden'>Close</span>
 * </a>
 */

// This is the actual *button* itself
.buttonlet-container {
  background: transparent;
  border-radius: 50%;
  padding: 0;

  &:focus .buttonlet {
    border-radius: 50%;
    box-shadow: 0 0 0 2px #273e3f;
  }

  &:focus .fa-circle {
    border-radius: 50%;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px 2px rgba(126, 188, 192, 0.6);
  }
}

.buttonlet {
  font-size: 16px;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  transition: color 200ms;
}

.buttonlet div {
  height: 34px;
  width: 34px;
}

.buttonlet-enabled {
  color: $blue-dark;
}

.buttonlet-disabled {
  color: $gray-darkish;
}

.buttonlet--focused,
.buttonlet-enabled:hover, .buttonlet-enabled:focus {
  color: $blue-lightish;
  cursor: pointer;
}

// Spacing positioning for buttonlets
.buttonlet-inner {
  left: 4px;
}

.buttonlet-refresh {
  &.buttonlet-local {
    left: 55px;
  }
}

.buttonlet-local {
  position: relative;
  float: left;
  top: 0;
  left: 0;
}

.buttonlet-quote-left {
  left: 140px;
}

.buttonlet-facebook {
  left: 95px;
}

.buttonlet-bsky {
  left: 50px;
}

.fbg-bsky {
  margin-top: 0.25rem;
}

.fbg-bsky:before {
  content: url('/img/icons/bsky_logo.svg');
  filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.5));
}

.buttonlet-override-circle::before {
  font-size: 0.7em;
  right: -2px;
  position: absolute;
}

.buttonlet-override-stack::before {
  font-size: 17px;
  position: absolute;
  top: -6px;
}

.buttonlet--plan {
  transition: color 0.2s;
}

.buttonlet--active-plan {
  color: rgb(200, 23, 14);
  transition: color 0.2s;
}

.buttonlet .fa-times {
  top: -1px; // tweak position on close buttons, because they're slightly wrong and it's been killing me --sdob
}
