.challenges {
    clear: both;
    // margin-bottom: 1rem;

    border-top: solid 1px $beige-mid;
    padding-top: .5rem;

    @media (min-width: $screen-md-up) {
        clear: none;
    }
}

.challenges--locked {
    border-top-color: $brown-light;
}
