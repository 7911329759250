.equipment-slot--is-changing {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.equipment__empty-slot {
  @extend .equipment-slot--is-changing;
}
