.quality-update {
    display: flex;
    margin-bottom: 1rem;
    &:last-of-type {
        margin-bottom: 0;
    }
}

.quality-update__left {
    margin-right: .5rem;
}