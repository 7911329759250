/**
 * Tab-focus
 * Provide an outline to focused elements for accessibility.
 */
@mixin tab-focus() {
	// Default
	outline: thin dotted;
	// WebKit
	outline: 5px auto -webkit-focus-ring-color;
}
