// At this breakpoint, we move the "Outfit updated" flash message back into the box
$outfit-control-success-message-breakpoint: 620px;

.outfit-controls {
  @media (min-width: $screen-sm-up) {
    display: grid;
    grid-template-columns: 80px 1fr;
  }
}

.outfit-controls--locked {
  align-items: center;
  border: 1px solid #756b5a;
  display: flex;
  height: 40px;
  justify-content: space-between;
  margin-right: 8px;
  max-width: 16rem;
  padding: calc(.5rem + 1px) calc(.5rem + 4px);
  width: 100%;
}

.outfit-controls--no-avatar {
  @media (min-width: $screen-sm-up) {
    grid-template-columns: 1fr;
  }
}

.outfit-controls__accessible-select {
  outline: 1px solid blue;

  &:focus {
    outline: 2px solid blue;
  }
}

.outfit-controls__cameo {
  border: 2px solid #383838;
  box-shadow: 0 1px 1px 0 #666666;
  margin-top: 8px;
  width: 64px;
}

.outfit-controls__cameo-container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.outfit-controls__cameo-container--legacy {
  justify-content: flex-end;
  margin-bottom: 1px;
  margin-left: 1px;
}

.outfit-controls__rubric {
  margin-right: .5rem;
  min-width: 60px;
}

.outfit-controls__search-field {
  margin: 0 8px 0 0;
  max-width: 16rem;
}

.outfit-controls__dropdown-container {
  border: solid 1px $brown-light;
}

.outfit-controls__dropdown-and-buttons {
  align-items: center;
  padding: 8px;
  display: flex;
  flex: 1;
  position: relative;

  @media (min-width: $screen-sm-up) {
    padding: 8px 16px;
  }

  @media (min-width: $outfit-control-success-message-breakpoint) {
    position: unset;
  }
}

.outfit-controls__outfit-dropdown {
  height: 40px;
  margin-right: 8px;
  max-width: 8rem;

  @media (min-width: $screen-sm-up) {
    max-width: 16rem;
  }
}

.outfit-controls__outfit-option--buy-new-outfit {
  color: $button-secondary-bg;
}

.outfit-controls__outfit-option--first-of-category {
  border-bottom: solid 1px #bbb;
  border-top: solid 1px #bbb;
  font-size: 8px;
}

.outfit-controls__outfit-option--disabled {
  background-color: #bbbbbb;
}

.outfit-controls__outfit-rename-form {
  align-items: center;
  display: flex;
  position: relative;
}

.outfit-controls__outfit-rename-form-error {
  background-color: $beige-mid;
  border: solid 1px $beige-light;
  bottom: -2rem;
  padding: .25rem;
  position: absolute;
  z-index: 1;
}

.outfit-controls__outfit-rename-field {
  margin-right: 8px;
  height: 40px;
  max-width: 12rem;
  background-color: #756b5a;
  border-color: #756b5a;
  padding: calc(.5rem + 1px) calc(.5rem + 4px);
}

.outfit-controls__save-outfit-success-message {
  background-color: $beige-mid;
  border: solid 1px $beige-light;
  bottom: -3rem;
  height: 40px;
  opacity: 1;
  padding: 8px;
  pointer-events: none;
  position: absolute;
  right: 8px;
  transition: opacity 500ms linear;
  z-index: 1;

  @media (min-width: $outfit-control-success-message-breakpoint) {
    background-color: transparent;
    height: unset;
    pointer-events: unset;
    position: unset;
  }
}

.outfit-controls__save-outfit-success-message--is-hiding {
  opacity: 0;
}

.outfit-controls__title-and-cameo {
  display: flex;
  flex-direction: column;
  @media (min-width: $screen-sm-up) {
    padding-top: 17px; // Manually aligned with first filter box
  }
}

.outfit-controls__title-and-cameo--legacy {
  @media (min-width: $screen-sm-up) {
    padding-top: 0;
  }
}

.outfit-controls__title {
  margin-left: 8px;
}
