.deck-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-right: 0.5rem;
}

.deck-info {
  height: 2rem; // Keep height constant so that loading doesn't cause jumpiness
  margin-top: .5rem;
  text-align: center;
}

.deck-info__cards-in-deck {
  font-weight: bold;
}

/*
WARNING! Be very careful about changing/removing any of the properties on
the .deck and .deck__image cards, unless you absolutely know what you're doing.
(They were added to force Chrome to keep the rendered deck image sharp when scaling.)
If you're reading this in the future, then perhaps these hacks are no longer necessary,
but they were in August of 2018. Forgive us; we meant well. --sdob
*/

.deck {
  border: none;
  padding: 0;

  &:focus {
    background-color: transparent;
    box-shadow: 0 0 0 2px #273e3f;
    border-radius: 2px;
    // TODO(sdob): we're not padding the focus shadow on smaller devices,
    //   since their button has the card back image as its background image --sdob
  }

  @media (max-width: $screen-sm-down) {
    // TODO: We should probably chop up the card back to avoid having to hack
    // background size and positioning
    background-position: 0;
    background-size: 200% 100%;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.8);
    cursor: pointer;
    height: 124px;
    transition: opacity 200ms;
    width: 96px;
  }

  @media (min-width: $screen-md-up) {
    backface-visibility: hidden;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.8);
    cursor: pointer;
    transform: translateZ(0) scale(1);
    transition: transform 0.15s ease-in-out;

    &:focus {
      margin: -4px;
      padding: 4px;
    }

    &:hover {
      transform: translateZ(0) scale(1.04);
    }
  }
}

.deck:hover .card--lg--cover {
  visibility: visible;
  opacity: 0.85;
  transition: opacity 0.3s, visibility 0.3s;
}

.deck--empty {
  @media (max-width: $screen-sm-down) {
    background-size: 100% 100%;
  }
}

.deck--fetching {
  cursor: default;
  opacity: 0.5;
}

.deck--full {
  cursor: default;

  &:hover {
    transform: scale(1);
  }

  @media (max-width: $screen-sm-down) {
    background-image: url('//images.fallenlondon.com/cards/deck-disabled.png');
    background-size: 100% 100%;
  }
}

.deck--small-media {
  display: flex;
  flex-direction: column;
}

.deck__image {
  backface-visibility: hidden;
  display: block;
  height: 125px;
  transform: translateZ(0);
}
