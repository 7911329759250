.shop {
  padding-left: .5rem;
  padding-right: .5rem;
}

.shop__item {
  margin-left: -.5rem;
  margin-right: -.5rem;
  padding: .5rem;
  padding: .5rem;
}

.shop__item:nth-child(2n+1) {
  background-color: rgba(0, 0, 0, 0.1);
}