.quality-requirement {
  box-shadow: none;
  margin: 0;

  & > div {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
    margin: 2px;
  }

  &.icon--circular > div {
    border-radius: 7px;
  }

  &.icon--world-quality > div {
    border-radius: 0 8px 0 8px;
  }

  /* Focus outlines */

  & > div:focus {
    border: 2px solid #273e3f;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px 2px rgba(126, 188, 192, 0.6);
    margin: 0;
  }

  &.icon--circular > div:focus {
  }
}