// profile page styles

.profile__display-item-container {
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  column-gap: 1rem;
}

.profile__display-item {
  position: relative;
  height: calc(5px + 64px * 130/100);
}

.profile__display-item--editable {
  &:hover {
    transform: scale(1.1);
  }
}

.profile__display-item-description {
  color: $beige-light;
  font-family: $font-family-serif;
  font-weight: normal;
  font-size: $font-size-base;
  word-wrap: break-word;
}

.profile__display-item-image {
  width: 64px;
  height: calc(64px * 130/100);
  box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.5);
  border: solid 2px $gray-darkish;
}

.profile__mantel-and-scrap {
  color: $white;
  column-gap: 1rem;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 1fr;
  margin-top: 2rem;
  row-gap: 1rem;

  @media (min-width: $screen-sm-up) {
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
  }
}

.profile__container {
  display: grid;
  column-gap: 1rem;
  row-gap: 1rem;
  grid-auto-flow: column;
  grid-template-rows: auto auto auto minmax(0, 1fr);
  margin: 0 auto;
  max-width: 1400px;

  position: relative;
  margin-top: -6.5rem;
  z-index: 1;

  @media (min-width: $screen-lg-up) {
    grid-auto-flow: row;
    grid-template-columns: auto minmax(0, 1fr);
    grid-template-rows: auto minmax(0, 1fr);
  }
}

.profile__card-container {
  column-gap: 1.5rem;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  margin: 1.5rem 1rem 0;
  max-width: 375px;
  justify-items: center;

  @media (min-width: $screen-lg-up) {
    grid-auto-flow: row;
    margin: 1rem -0.5rem 0 auto;
    max-width: 500px;
  }
}

.profile__identity-container {
  padding: 0 1rem;
}

.profile__inventory-theysay-container {
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: auto minmax(0, 1fr);
  padding: 1rem;
  row-gap: 1rem;
  grid-template-columns: 1fr;
  column-gap: 1rem;
  margin: 0 auto;
  max-width: 340px;

  @media (min-width: $screen-sm-up) {
    grid-template-columns: 1fr auto 300px 1fr;
    margin: 0;
    max-width: none;
  }

  @media (min-width: $screen-lg-up) {
    grid-template-columns: 1fr auto 320px 1fr;
    margin: 3rem 0 auto;
    padding: 0 0 1rem 1rem;
  }
}

.profile__player-journal {
  margin-bottom: 1rem;
}

.profile__banner {
  align-items: center;
  display: flex;
  height: 350px;
  position: relative;
  background: -moz-linear-gradient(to bottom, transparent 0%, transparent 65%, $gray-dark 100%);
  background: -webkit-gradient(to bottom, transparent 0%, transparent 65%, $gray-dark 100%);
  background: -webkit-linear-gradient(to bottom, transparent 0%, transparent 65%, $gray-dark 100%);
  background: -o-linear-gradient(to bottom, transparent 0%, transparent 65%, $gray-dark 100%);
  background: -ms-linear-gradient(to bottom, transparent 0%, transparent 65%, $gray-dark 100%);
  background: linear-gradient(to bottom, transparent 0%, transparent 65%, $gray-dark 100%);
}

.profile__banner-backdrop {
  background-position: 50% 0;
  background-repeat: no-repeat;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.profile__card {
  border: solid 4px $gray-darkish;
  width: calc(100% - .5rem);
  box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.5);

  @media (min-width: $screen-sm-up) {
    height: 220px;
    width: auto;
    margin: 0 0.125rem 0 0.25rem;
  }

  @media (min-width: $screen-lg-up) {
    height: 220px;
    width: auto;
    margin: 0 0.25rem 0 1.5rem;
  }
}

.profile__card-framed {
  width: calc(100% - 0.5rem - 12px);
  grid-area: 1 / 1 / 1 / 1;
  padding-top: 4px;

  @media (min-width: $screen-sm-up) {
    height: 216px;
    width: auto;
    padding-top: 4px;
  }
}

.profile__frame {
  grid-area: 1 / 1 / 1 / 1;
  height: auto;
  width: calc(100% - 0.5rem - 8px + 30px);
  margin-top: -11px;

  @media (min-width: $screen-sm-up) {
    width: calc(100% - 0.5rem - 8px + 30px);
    margin-top: -10px;
  }

  @media (min-width: $screen-lg-up) {
    width: calc(100% - 0.5rem - 8px);
    margin-top: -10px;
  }
}

.profile__card-header {
  color: $white;
  margin-top: 0;
  text-align: center;

  @media (min-width: $screen-sm-up) {
    margin-top: 0.5rem;
  }
}

.profile__card-and-frame {
  display: grid;
  grid-auto-flow: row;
  justify-items: center;
  margin-left: -0.25rem;
}

.profile__card-no-frame {
  @extend .profile__card-and-frame;

  @media (min-width: $screen-sm-up) {
    padding-right: 0.75rem;
  }
}

.profile__description-wrapper {
  border-bottom: solid 1px $brown-light;
  column-gap: 1rem;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 1fr auto;
  padding-bottom: 1rem;
}

.profile__description {
  color: $beige-light;
  font-size: $font-size-base * 2; // sqrt(4)
  margin-bottom: 1rem;
}

.profile__description-location {
  align-self: self-end;
  color: $beige-light;
  display: inline;
  font-size: $font-size-base * 2; // sqrt(4)
  margin-bottom: 1rem;
}

.profile__character-name {
  color: $white;
  font-size: $font-size-base * 3.162; // sqrt(10)
  font-weight: $font-weight-bold;
  padding: 0;
  margin: 0;
  text-shadow: 0 2px 3px black;
}

.profile__inventory {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 0.5rem;
  border: 2px solid $gray-darkish;
  padding: 0.5rem;
  box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.5);
}

.profile__inventory-header {
  text-align: right;

  .heading--2 {
    color: $white;
  }

  .heading--4 {
    color: $beige-light;
    font-weight: $font-weight-medium;
  }
}

.profile__inventory--row-1 {
  display: grid;
  grid-template-columns: 64px minmax(0, 1fr) 64px;
  column-gap: 0.75rem;
  row-gap: 0.5rem;
  padding: 0 0.4rem;

  @media (min-width: $screen-lg-up) {
    padding: 0 0.75rem;
  }
}

.profile__inventory--cluster {
  display: grid;
  grid-template-columns: repeat(2, 50px);
  column-gap: 0.5rem;
  row-gap: 0.25rem;
  place-self: center;
}

.profile__inventory--row-2 {
  display: grid;
  grid-template-columns: repeat(3, minmax(64px, 1fr));
  column-gap: 0.5rem;
  place-self: center;
  width: 100%;
}

.profile__inventory--row-3 {
  display: grid;
  grid-template-columns: minmax(0, 1fr) 64px 64px;
  column-gap: 1.75rem;
  row-gap: 0.5rem;
  padding: 0 0.4rem;

  @media (min-width: $screen-lg-up) {
    padding: 0 0.75rem;
  }

  .profile__inventory--cluster {
    margin-top: -0.3rem;
  }
}

.profile__inventory--row-4 {
  display: grid;
  grid-template-columns: 64px 64px minmax(0, 1fr);
  column-gap: 1.75rem;
  row-gap: 0.5rem;
  padding: 0 0.4rem;

  @media (min-width: $screen-lg-up) {
    padding: 0 0.75rem;
  }

  .profile__inventory--cluster {
    margin-top: -0.3rem;
  }
}

.profile__inventory-item {
  display: grid;
  align-items: center;
  justify-content: center;
  min-height: 50px;
}

.profile__inventory-item-large {
  @extend .profile__inventory-item;
}

.profile__inventory-item-image {
  @extend .profile__display-item-image;

  height: 50px;
  width: 50px;
}

.profile__inventory-item-image-large {
  @extend .profile__display-item-image;

  height: 110px;
  width: calc(110px * 100/130);
}

.profile__inventory-switcher {
  height: 35px;
  width: 40px;
  margin-top: 0.5rem;
  padding: 0.5rem 0;
  vertical-align: top;

  span {
    vertical-align: middle;
  }
}

.profile__snippet.snippet {
  margin-top: 0;

  @media (max-width: $screen-xs-down) {
    margin-bottom: 1rem;
  }
}

.profile__contacts-container {
  position: relative;
}

.profile__contacts-alert {
  background-color: rgba(0.11, 0.11, 0.11, 0.75);
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  padding: 2px;
  position: absolute;
  top: -1.5rem;
  right: 0;
  white-space: nowrap;
}
