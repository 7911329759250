.fan-favourite {
  filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.5));
  position: absolute;
  right: 0;
  top: -4px;

  :focus .fan-favourite__icon {
    outline: solid 1px red;
  }
}

.fan-favourite__icon {
  background: $button-secondary-border;
}

.fan-favourite:hover .fan-favourite__icon,
.fan-favourite:focus .fan-favourite__icon {
  background-color: lighten($button-secondary-border, 8%);
}
