/**
 * Bubble mixin.
 * For putting tiny numbers into little colored circles.
 */
@mixin bubble($color, $background) {
	font-size: 10px;
	border-radius: 11px;
	padding: 2px 4px;
	min-width: 17px;
	text-align: center;
	background: $background;
	color: $color;
	.bastard & {
		color: $color;
	}
}

