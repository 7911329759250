
/****************************************************************************
 * COLORS IN USE.
 * Should mostly use the base colors, rather than defining new ones.
 */

// Dark on light.
$bg-color: $gray-dark;
$text-color: $beige-mid;
$link-color: $blue-light;
$link-hover-color: lighten($blue-light, 15%);

// Light on dark.
$inverse-bg-color: $beige-mid;
$inverse-text-color: $brown-dark;
$inverse-link-color: $blue-dark;
$inverse-link-hover-color: darken($blue-dark, 15%);

$banner-bg: #222;
$banner-border-color: #303030;

$premium-color: $yellow;
$enhanced-main-color: #b060d3;
$enhanced-button-color: #9d4bc1;
$enhanced-light-color: #f5deff;
$enhanced-dark-color: #882ab1;

// The base colors for buttons, which will be lightened/darkened for hover,
// active etc states.
$button-primary-bg: darken($blue-mid, 5%);
$button-primary-color: $blue-lightest;
$button-primary-color-more: darken($blue-lightest, 15%);
$button-primary-border: $blue-dark;

$button-secondary-bg: $yellow;
$button-secondary-color: $gray-dark;
$button-secondary-color-more: lighten($gray-dark, 25%);
$button-secondary-border: darken($yellow, 3%);

$button-tertiary-bg: transparent;
$button-tertiary-color: $blue-dark;
$button-tertiary-color-more: darken($blue-mid, 15%);
$button-tertiary-border: $blue-dark;

$button-dangerous-bg: $red-mid;
$button-dangerous-color: $red-lightest;
$button-dangerous-color-more: darken($red-lightest, 15%);
$button-dangerous-border: $red-dark;

$button-disabled-bg: $gray-mid;
$button-disabled-color: $gray-dark;
$button-disabled-color-more: lighten($gray-dark, 5%);
$button-disabled-border: $gray-dark;

$button-padding-vertical: 10px;
$button-padding-horizontal: 20px;
$button-padding-vertical-sm: 5px;
$button-padding-horizontal-sm: 15px;

// Progress bars.
$progress-default-bg: $blue-lightish;
$progress-default-border: $blue-light;
$progress-menace-bg: $red-mid;
$progress-menace-border: $red-light;
$progress-target-bg: $green-mid;
$progress-target-border: $green-light;

$media-priority-0-bg: $white; // TODO
$media-priority-0-color: $black; // TODO
$media-priority-1-bg: #f2bd4f;
$media-priority-1-color: $brown-dark;
$media-priority-2-bg: #424242;
$media-priority-2-color: $gray-lightest;
$media-priority-3-bg: $brown-mid;
$media-priority-3-color: $brown-dark;

$footer-bg: $gray-darker;
$footer-color: $gray-mid;
$footer-button-active-bg: $gray-darkish;
$footer-button-active-color: $gray-lightest;

$header-button-color: $beige-light;
$header-button-active-color: $beige-lightest;

// Event Category border colours
$event-border-gold: #CDAF61;
$event-border-silver: #ABABAB;
$event-border-bronze: #C2966C;
$event-border-black: #676767;
$event-border-purple: #9C6EA3;

// $container-lg:	(1150px + $grid-gutter-width);

// Dimensions of the login-page source image
$background-image-height: 1080px;
$background-image-width: 1920px;

$deviless-image-height: 748px;
$deviless-image-width: 596px;

$container-fluid-gutter-width: $grid-gutter-width * 2;

/****************************************************************************
 * HEADER AND FOOTER (narrow widths).
 */

$header-xs-height: 140px;
$footer-xs-height: 44px;

/****************************************************************************
 * SIDEMENU
 */

$sidemenu-width: 280px;
$banner-xs-height: 140px;
$footer-xs-height: 44px;
$banner-fixed-height: 44px;
$footer-xs-margin: 30px;

/****************************************************************************
 * ICON SIZES
 */

$aspect-icon-size: 16px;

/***************************************************************************
 * BUTTONLETS
 */

$buttonlet-color-inactive: $blue-lightish;
$buttonlet-color-active: $gray-lightest;
$buttonlet-color-complete: $yellow;

/***************************************************************************
 * FORMS and INPUTS
 */

$input-bg: $brown-dark;
$input-bg-disabled: $gray-light;
$input-placehholder: $brown-mid;
$input-color: $brown-light;
$input-border: $brown-lightest;

$select-bg: $brown-light;
$select-border: $brown-mid;

$input-border-focus: $blue-lightish;

$input-height-base: $line-height-computed + 5px * 2 + 2;

/***************************************************************************
 * MODAL
 */

$modal-backdrop-bg: $black;
$modal-backdrop-opacity: 0.5;

// Width when the browser is at least this wide:
$modal-width: 600px;

/***************************************************************************
 * ICONS
 */

$base-icon-size: 16px;

/***************************************************************************
* MISC
*/

//** Disabled cursor for form controls and buttons.
$cursor-disabled: not-allowed;

$panel-bg: $brown-light;

// $icon-path:  "https://images.fallenlondon.com/static/icons/";
