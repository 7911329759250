/**
 * FX.
 * Currently, these are applied randomly by fl.fx.js.
 * Add the class js-fx-random to an element for it to have random effects
 * applied to it occasionally.
 *
 * NOTE: If you change the duration of any of these, change the durations in
 * fl.fx.js too.
 */


/*****************************************************************************
 * Wobble X
 * Wobbles element left and right.
 */

.fx-wobble-x {
	-webkit-animation: wobble 1s ease-in;
			animation: wobble 1s ease-in;
}

@-webkit-keyframes wobble {
	0%   { -webkit-transform: translateX(0%); }
	15%  { -webkit-transform: translateX(-1%); }
	30%  { -webkit-transform: translateX(1%); }
	15%  { -webkit-transform: translateX(-1%); }
	30%  { -webkit-transform: translateX(1%); }
	15%  { -webkit-transform: translateX(-1%); }
	30%  { -webkit-transform: translateX(1%); }
	45%  { -webkit-transform: translateX(-1%); }
	60%  { -webkit-transform: translateX(0.5%); }
	75%  { -webkit-transform: translateX(-0.3%); }
	100% { -webkit-transform: translateX(0%); }
}
@keyframes wobble {
	0%   { transform: translateX(0%); }
	15%  { transform: translateX(-2%); }
	30%  { transform: translateX(1.5%); }
	45%  { transform: translateX(-1%); }
	60%  { transform: translateX(0.5%); }
	75%  { transform: translateX(-0.3%); }
	100% { transform: translateX(0%); }
}

/*****************************************************************************
 * Blur
 * Makes element blurry then back to normal.
 * Doesn't work in IE.
 */

.fx-blur {
	-webkit-animation: blur 5s ease-out;
			animation: blur 5s ease-out;
}

@-webkit-keyframes blur {
	60%		{ -webkit-filter: blur(3px); }
	100%	{ -webkit-filter: blur(0px); }
}
@keyframes blur {
	60%		{ filter: blur(3px); }
	100%	{ filter: blur(0px); }
}

/*****************************************************************************
 * Orbit
 * Moves element down, then two orbits anti-clockwise, then back to position.
 */

$orbit-start-duration:	0.5s;
$orbit-duration:		4s;
$orbit-count:			2;
$orbit-end-duration:	0.5s;
$orbit-radius:			5px;

.fx-orbit {
	-webkit-animation: toOrbit $orbit-start-duration linear, inOrbit $orbit-duration linear 0s $orbit-count, fromOrbit $orbit-end-duration linear;
			animation: toOrbit $orbit-start-duration linear, inOrbit $orbit-duration linear 0s $orbit-count, fromOrbit $orbit-end-duration linear;

	-webkit-animation-delay: 0s, $orbit-start-duration, ($orbit-duration * $orbit-count) + $orbit-start-duration;
			animation-delay: 0s, $orbit-start-duration, ($orbit-duration * $orbit-count) + $orbit-start-duration;
}

@-webkit-keyframes toOrbit {
	from { -webkit-transform: translateY(0px); }
	to   { -webkit-transform: translateY($orbit-radius); }
}
@keyframes toOrbit {
	from { transform: translateY(0px); }
	to   { transform: translateY($orbit-radius); }
}

@-webkit-keyframes inOrbit {
	from { -webkit-transform: rotate(  0deg) translateY($orbit-radius) rotate(   0deg); }
	to   { -webkit-transform: rotate(360deg) translateY($orbit-radius) rotate(-360deg); }
}
@keyframes inOrbit {
	from { transform: rotate(  0deg) translateY($orbit-radius) rotate(   0deg); }
	to   { transform: rotate(360deg) translateY($orbit-radius) rotate(-360deg); }
}

@-webkit-keyframes fromOrbit {
	from { -webkit-transform: rotate(360deg) translateY($orbit-radius) rotate(-360deg); }
	to   { -webkit-transform: translateY(0px); }
}
@keyframes fromOrbit {
	from { transform: rotate(360deg) translateY($orbit-radius) rotate(-360deg); }
	to   { transform: translateY(0px); }
}

