.gate-event {
  font-family: $font-family-serif;
}

.gate-event__buttons {
  margin-top: 0.5rem;
  text-align: right;
}

.gate-event__image {
  margin-right: 0.5rem;
}

.gate-event__teaser {
  font-size: 80%;
}

.gate-event__title {
  font-weight: bold;
  margin-bottom: 0.25rem;
}

.gate-event__unlock-button {
  font-size: 10px;
}

.gate-storylet__root {
  display: flex;
  flex-wrap: wrap;
}
