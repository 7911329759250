.exchange-ui__close-button--md-up {
  position: absolute;
  right: -18px;
  top: -18px;
}

.exchange-ui__close-button--sm-down {
  position: relative;
  right: -8px;
  top: -6px;
}

.exchange-ui__controls {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (min-width: $screen-sm-up) {
    width: auto;
  }
}

.exchange-ui__form {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;

  @media (min-width: $screen-sm-up) {
    flex-direction: row;
  }
}

.exchange-ui__header {
  align-items: flex-start;
  border-bottom: 1px solid $brown-light;
  display: flex;
  justify-content: space-between;
  padding-bottom: .5rem;
  width: 100%;
}

.exchange-ui__item {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.exchange-ui__rubric-and-controls {
  border-top: 1px solid $brown-light;
  padding-top: .5rem;
}

.exchange-ui__state-change-button {
  @media (min-width: $screen-sm-up) {
    &:not(first-of-type) {
      margin-left: .5rem;
    }
  }
}

.exchange-ui__state-change-button:first-child {
  margin-left: 0;
}

.exchange-ui__submit-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  width: 100%;

  @media (min-width: $screen-sm-up) {
    display: block;
    margin-top: 0;
    width: auto;
  }
}
