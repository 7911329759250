/**
 * NAV
 * Based on Bootstrap's navs.
 *
 * For navigating between bits of content within the page. These might be panes
 * which switch visibility with JS, or they might be panes that have no
 * content loaded into them via AJAX.
 *
 * Use like this, with either the nav--tabs class or nav--stacked:
 *
 * 	<nav class="nav nav--tabs>
 * 		<ul role="tablist">
 * 			<li class="nav__item active" role="presentation"><a href="#">Home</a></li>
 * 			<li class="nav__item" role="presentation"><a href="#">Profile</a></li>
 * 			<li class="nav__item" role="presentation"><a href="#">Messages</a></li>
 * 		</ul>
 * 	</nav>
 *
 * Then have one or more <div class="tab-content"></div> or <div class="tab-stacked"></div> containing the bits of content.
 *
 * See layout/_nav-content.scss for styles for the content that the .nav's
 * navigate between.
 */

/*****************************************************************************
 * Base class
 */
.nav {
  margin-bottom: 0;
  padding-left: 0; // Override default ul/ol
  list-style: none;

  @include clearfix;

  font-family: $font-family-serif;
  font-weight: $font-weight-bold;

  .nav__item {
    position: relative;
    display: block;

    > a {
      position: relative;
      display: block;
      padding: 5px 8px;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }
}

.nav__button {
  position: relative;
  display: block;
  padding: 5px 8px;
}

.list-roman,
.nav__list {
  padding-left: 0.25rem;
}

/*****************************************************************************
 * Tabs
 * In addition to .nav
 */

.nav--tabs {
  margin: 0 10px;

  .nav__item {
    float: left;
    text-transform: uppercase;

    > a {
      margin-right: 2px;
      line-height: $line-height-base;
      border-bottom: 7px solid transparent;
      transition: border-bottom-color 200ms, color 200ms;

      &:hover {
        border-bottom-color: $beige-mid;
      }
    }

    &.notifying > a{
      animation: glow .5s infinite alternate;
    }
    &.active > a {
      &,
      &:hover,
      &:focus {
        color: $text-color;
        cursor: default;
        border-bottom-color: #aaa08c;
      }
    }
  }
}

@keyframes glow {
  from {
    text-shadow: 2px 2px 5px #b3b3b3;
  }
  to {
    text-shadow: 2px 2px 8px #92d1d5;
  }
}

.nav__button--tabs {
  margin-right: 2px;
  line-height: $line-height-base;
  border-bottom: 7px solid transparent;
  transition: border-bottom-color 200ms, color 200ms;

  &:hover {
    border-bottom-color: $beige-mid;

    button {
      color: #c9e8ea;
    }
  }
}

.nav__button--tabs.active {
  cursor: default;
  border-bottom-color: $beige-mid;

  & > button {
    color: $text-color;
  }
}

/* In addition to .nav; hide at narrowest width */
.nav--sm {
  display: none;

  // TODO: Replace with variable when SASS breakpoints are sorted
  @media (min-width: 992px) {
    display: block;
  }
}

/*****************************************************************************
 * Stacked
 * In addition to .nav
 */
.nav--stacked {
  // Would be 6 (one quarter of the total width) but we want a bit more space
  // between it and its content).
  // @include make-sm-column(5);

  @media (min-width: $screen-lg-up) {
    float: left;
    width: calc(100% * 5/24);
  }

  margin-bottom: 1em;

  .nav__item {
    border-bottom: 2px solid $brown-light;

    &:last-child {
      border-bottom: none;
    }

    > .nav__button,
    > a {
      padding-left: 0;
    }

    // TODO: remove the specificity when we know it's working;
    &.active > .nav__button,
    &.active > a {
      &,
      &:hover,
      &:focus {
        color: $text-color;
        cursor: default;
      }

      .bastard &,
      .bastard &:hover,
      .bastard &:focus {
        color: $inverse-text-color;
      }
    }
  }

  /**
   * Used like
   * <li class="nav__item">
   *	<a href="#">
   *		<span class="nav__item__bullet" aria-hidden="true">iii.</span>
   *		My link
   *	</a>
   * </li>
   */
  .nav__item__bullet {
    color: $text-color;

    .bastard & {
      color: $brown-mid;
    }
  }
}
