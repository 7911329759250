.braintree-view__button-container {
  margin-bottom: 1em;
  text-align: center;

  @media (min-width: $screen-sm-min) {
    text-align: right;
  }
}

.complete-transaction {
  @media (min-width: $screen-sm-min) {
    width: 25%;
  }
}

.fate-option {
  display: flex;
}

.fate-option--breakdown-visible {
  .my-price small {
    opacity: 1;
    visibility: visible;
  }
}

.fate-option--selected {
  background-color: rgba(76, 119, 123, 0.2);
}

.fate-option__label {
  cursor: pointer;
  font-weight: bold;
  margin-bottom: 0;
  padding: .5em 0 .5em .5em;
  width: 100%;

  &:hover {
    background-color: rgba(76, 119, 123, 0.2);
  }
}

.fate-option__radio {
  display: none;
}

.my-price small {
  // display: none;
  // height: 0;
  font-weight: bold;
  opacity: 0;
  transition: opacity 200ms;
  visibility: hidden;
}

.my-price small.vat-details--visible {
  font-weight: normal;
  height: auto;
  opacity: 1;
  visibility: visible;
}

.nex-amount {
  margin-bottom: 2em;

  @media (min-width: $screen-sm-min) {
    margin-bottom: 0;
  }
}

.nex-amount__list {
  display: flex;
  flex-wrap: wrap;

  @media (max-width: $screen-xs-down) {
    flex-direction: column;
  }
}

.nex-form {
  @media (min-width: $screen-sm-min) {
    display: flex;
  }
}

.payment-information {
  @media (min-width: $screen-sm-min) {
    padding-right: 30px;
    width: 40%;
  }
}

.payment-toggle {
  margin: 16px 20px 24px;

  @media (min-width: $screen-sm-min) {
    margin: 16px 24px 20px 0;
  }
}

.security-notice {
  background-color: #000;
  border-radius: 1.5em;
  color: #dad2c5;
  font-size: 1em;
  font-weight: 700;
  padding: .3em .8em;
  text-transform: uppercase;
}

[data-braintree-id="choose-a-way-to-pay"] {
  position: static;
}

.purchase-disclaimer {
  padding-top: 2rem;
  padding-bottom: 0.5rem;

  a {
    color: $inverse-link-color;

    &:hover,
    &:focus {
      color: $inverse-link-hover-color;
    }
  }
}
