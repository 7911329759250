/*****************************************************************************
 * MEDIA
 *
 * Used for roots, branches and messages panels.
 *
 * Based on Bootstrap's media object.
 * http://getbootstrap.com/components/#media
 *
 * The base .media element only applies basic structure.
 * Add a variant to give it prettier styles:
 * .media--root or .media--message
 *
 * eg:
 *
 * <div class="media media--root">
 *   <div class="media__left">
 *     <a href="#">
 *       <img class="media__object" src="..." alt="...">
 *     </a>
 *   </div>
 *   <div class="media__body">
 *     <h4 class="media__heading">Media heading</h4>
 *     ...
 *   </div>
 *   <div class="media__right">
 *     <a href="#">
 *       <img class="media__object" src="..." alt="...">
 *     </a>
 *   </div>
 * </div>
 *
 * .media__left and .media__right are optional elements.
 *
 * Add .media__middle or .media__bottom to the *left, *right or *body elements
 * to align their contents vertically.
 */

.panel {
  width: 100%;
  position: relative;
  margin-bottom: 1em;
  background-color: #a89e8c;
  padding: 1px;
}

.profile-panel {
  width: 100%;
  overflow: auto;
  margin-bottom: 1em;
  background-color: #a89e8c;
  background-repeat: no-repeat;
  padding: 10px;
  padding-left: 20px !important;
  padding-right: 20px !important;
  color: black;
}

.panel-inventory {
  width: 20%;
}

.panel p:last-child {
  margin-bottom: 0;
}

/**
 * Base .media class - scaffolding only, no 'paint'.
 */
.media {
  // Proper spacing between instances of .media
  margin-top: 15px;

  @media (min-width: $screen-sm-up) {
    display: flex;

    // flex-direction: column;
  }
}

.media--semi-transparent {
  opacity: 0.5;
}

.media__body {
  flex: 1;
}

// Stuff directly from the CMS gets hit by this rule; we need to be specific
.media__body > p {
  hyphens: auto;
  text-align: justify;
}

.media__content {
  display: flex;
  flex-wrap: wrap;
}

.media__left {
  float: left;
  padding-bottom: 10px;
  padding-right: 10px;

  @media (min-width: $screen-sm-up) {
    float: none;
  }
}

.media__object {
  display: block;
}

.media__right {
  padding-left: 10px;
}

/*****************************************************************************
 * Variations for different types of media block.
 */

// Common styles for the main media types.
.media--root,
.media--message {
  background-color: $beige-light;
  background-repeat: repeat;
  background-position: 0 0;
  box-shadow: 0 2px 3px 0 $brown-light;
}

.media--root {
  background-position: bottom left, bottom left;
  background-repeat: no-repeat, repeat;
  margin-top: 0;
  padding: 0.5rem;
  position: relative;

  @media (min-width: $screen-sm-up) {
    display: flex;
    flex-wrap: wrap;
  }

  &::after {
    clear: both;
    content: '';
    display: block;
  }
}

.media--message {
  margin-top: 1rem;
  padding: 0.5rem;

  &:first-of-type {
    margin-top: 0;
  }
}

.media--quality-updates {
  flex-direction: column;
}

.media__heading {
  hyphens: auto;

  @media (min-width: $screen-sm-up) {
    // Leave space for the .buttonlet--plan.
    margin-right: 40px;
  }
}

.media--message {
  flex-wrap: wrap;
  position: relative;
  word-break: break-word; // Prevent extra-long user-generated content from breaking layout

  @media (min-width: $screen-sm-up) {
    display: block;
  }
}

.media--message__time {
  white-space: nowrap;
  color: $brown-mid;
}

.media--challenge {
  background: $beige-mid;
  padding: 5px 8px 8px;
  margin-bottom: 10px;
}

/*****************************************************************************
 * Variations for different conditions of media block.
 */

// Generally applied to .branch:
.branch.media--locked,
.media--locked {
  background-color: $gray-light;
  border-top-color: $gray-lightest;

  &:before {
    border-color: $gray-mid;
  }

  .media--challenge {
    background: $gray-lightish;
  }
}

// Generally applied to .branch:
.branch.media--fate-locked,
.media--fate-locked {
  background-color: $yellow-light;

  &:before {
    border-color: $yellow;
  }

  .media--challenge {
    background: $yellow;
  }
}

/*****************************************************************************
 * Variations for urgency of media block.
 */

.media--priority-0,
.media--priority-1,
.media--priority-2,
.media--priority-3 {
  border: none;

  // Undo the extra-border on the default .media--message.
  &:before {
    content: none;
  }
}

.media--priority-0 {
  @include background-radial-gradient(lighten($media-priority-0-bg, 15%), $media-priority-0-bg);

  &,
  & .heading {
    color: $media-priority-0-color;
  }
}

.media--priority-1 {
  @include background-radial-gradient(lighten($media-priority-1-bg, 15%), $media-priority-1-bg);

  &,
  & .heading {
    color: $media-priority-1-color;
  }
}

.media--priority-2 {
  @include background-radial-gradient(lighten($media-priority-2-bg, 10%), $media-priority-2-bg);

  &,
  & .heading {
    color: $media-priority-2-color;
  }
}

.media--priority-3 {
  @include background-radial-gradient(lighten($media-priority-3-bg, 10%), $media-priority-3-bg);

  &,
  & .heading {
    color: $media-priority-3-color;
  }
}

/*****************************************************************************
 * Things wrapped around .media elements.
 */

/**
 * Media list.
 * eg:
 *  <ul class="media-list">
 *    <li class="media"></li>
 *  </ul>
 */
.media-list {
  padding-left: 0;
  list-style: none;
}

.media-group {
  // The same as for .media:
  margin-top: 15px;

  .media {
    margin-top: 0;
    border-bottom: 1px solid $beige-mid;

    &:first-child {
      border-radius: 5px 5px 0 0;
    }

    &:last-child {
      border-bottom: none;
      border-radius: 0 0 5px 5px;
    }
  }

  .media--priority-1 {
    border-bottom-color: darken($media-priority-1-bg, 10%);
  }

  .media--priority-2 {
    border-bottom-color: darken($media-priority-2-bg, 5%);
  }

  .media--priority-3 {
    border-bottom-color: darken($media-priority-3-bg, 5%);
  }
}

.promo {
  @media (max-width: ($screen-sm-up - 1)) {
    background-color: #a89e8c;
    padding: 1rem;
  }
}

.promo__body {
  @media (min-width: $screen-sm-up) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.promo__body::after {
  clear: both;
  content: '';
  display: block;
}

.promo__panel {
  @media (min-width: $screen-sm-up) {
    background-color: #a89e8c;
    padding: 0.5rem;
  }
}
