/**
 * Grid system
 * From Bootstrap: http://getbootstrap.com/css/#grid
 * https://github.com/twbs/bootstrap-sass/blob/master/assets/stylesheets/bootstrap/_grid.scss
 */



// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but without any defined
// width for fluid, full width layouts.

.container-fluid {
	// Force it to have more left/right padding than default, because
	// we have very narrow gutters:
	// ( * 2 because it gets divided by 2 in the mixin)
	@include container-fixed(10px);

	@media (min-width: $screen-sm-min) {
		@include container-fixed(($container-fluid-gutter-width * 2));
	}
}


// Row
//
// Rows contain and clear the floats of your columns.

.row {
	@include make-row;
}

/****************************************************************************
 * Stuff after this is used when specifying grid classes in the HTML.
 * But we're trying to keep the HTML semantic by using the grid mixins
 * on classes specific to this project. Phil.
 *
 * Therefore, all the below is commented out for the moment, as it generates
 * hundreds of lines of CSS.
 */


// Columns
//
// Common styles for small and large grid columns

//@include make-grid-columns;


// Extra small grid
//
// Columns, offsets, pushes, and pulls for extra small devices like
// smartphones.

//@include make-grid(xs);


// Small grid
//
// Columns, offsets, pushes, and pulls for the small device range, from phones
// to tablets.

//@media (min-width: $screen-sm-min) {
	//@include make-grid(sm);
//}


// Medium grid
//
// Columns, offsets, pushes, and pulls for the desktop device range.

//@media (min-width: $screen-md-min) {
	//@include make-grid(md);
//}


// Large grid
//
// Columns, offsets, pushes, and pulls for the large desktop device range.

//@media (min-width: $screen-lg-min) {
	//@include make-grid(lg);
//}
