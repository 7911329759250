.leaflet-container {
  background: rgb(12, 12, 12); // nice background
  // background: red; // debug background

  //noinspection CssOverwrittenProperties
  height: 100vh; // Fallback for devices that don't support CSS variables
  //noinspection CssOverwrittenProperties
  height: calc(var(--vh, 1vh) * 100);

  @media (min-width: $screen-md-up) {
    border: solid 1px #4c777b;
    box-shadow: 0 0 16px black;
    margin: auto;
    max-height: calc(3000px / 4);
    width: 80vw;
  }

  @media (min-width: calc(1.25 * 5220px / 4)) {
    width: calc(5220px / 4);
  }
}

.leaflet-container--unterzee {
  @media (min-width: $screen-md-up) {
    // max-height: calc(1177px / 2);
    max-height: (3000px / 4);
  }
  @media (min-width: calc(2048px / 2)) {
    width: 80vw;
    // max-width: calc(2048px / 2);
    max-width: calc(5220px / 4);
  }
}

/*
.leaflet-container--london {
  @media (min-width: $screen-md-up) {
    max-height: calc(3000px / 4);
  }
  @media (min-width: calc(5220px / 4)) {
    width: calc(5220px / 4);
  }
}

 */

.modal--map__content--fallback .leaflet-container {
}

// Apply a vignette to the map
.leaflet-container::before {
  background: radial-gradient(transparent 70%, #00000080);
  filter: blur(2px);
  bottom: 0;
  box-shadow: inset 0 0 100px 10px #000;
  content: ' ';
  left: 0;;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 500;
}
