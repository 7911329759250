.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Body--open-map {
  overflow: hidden;

  #root {
    filter: blur(1px);
  }
}

.ReactModal__Overlay,
.ReactModal__Content {
  opacity: 0;
}

.ReactModal__Overlay--after-open,
.ReactModal__Content--after-open {
  opacity: 1;
  transition: opacity 400ms;
}

.ReactModal__Overlay--before-close,
.ReactModal__Content--before-close {
  opacity: 0;
}

.modal--avatar-list {
  width: 100%;
}

.modal--gate-storylet__content {
  border: solid 2px $gray-darkish;
  color: rgb(40, 37, 32);
  box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.5);
  max-height: 90vh;
  height: auto;
  overflow-y: auto;
  padding: 0.5rem;

  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.5) transparent;

  @media (min-width: $screen-sm-up) {
    max-height: 75vh;
    max-width: 75vw;
  }

  @media (min-width: $screen-lg-up) {
    max-width: 50rem;
  }

  &::-webkit-scrollbar {
    height: 16px;
    width: 18px;
  }

  &::-webkit-scrollbar-button { // up/down buttons
    display: none;
    height: 0;
    width: 0;
  }

  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: rgba(0, 0, 0, 0.5);
    border: solid 4px rgba(0, 0, 0, 0);
    border-radius: 9px;
  }
}

.modal--gate-storylet__overlay.modal--tooltip-like__overlay {
  // Higher than the compass to close out the map
  align-items: flex-end;
  z-index: $z-index-gate-storylet-modal-overlay;

  @media (min-width: $screen-sm-up) {
    align-items: center;
  }
}

.modal-dialog__overlay--after-open.modal--map-action-refresh__overlay {
  z-index: $z-index-map-action-refresh-overlay;
}

.modal-dialog__overlay--after-open.modal--map-exceptional-friend-modal__overlay {
  z-index: $z-index-map-exceptional-friend-overlay;
}

.modal--map-exceptional-friend-modal__content {
  overflow: auto;
}

.modal--map__content {
  transition: opacity 400ms ease-in-out;
  position: relative;
  width: 100vw;

  @media (min-width: $screen-md-up) {
    margin-left: auto;
    margin-right: auto;
    width: 80vw;
  }

  @media (min-width: $screen-xl-up) {
    max-width: calc(5220px / 4);
  }
}

.modal--map__content--fallback {
  & .leaflet-container::before {
    content: none;
  }
}

.modal--share-dialog__overlay,
.modal-dialog__overlay--after-open.modal--share-dialog__overlay {
  z-index: $z-index-gate-storylet-share-dialog-overlay;
}

.modal--tooltip-like__content {
  background-color: $gray-dark;
  border: solid 2px $gray-darkish;
  box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.5);
  padding: 1rem;
  position: relative;
  max-height: 80vh;
  max-width: 80vw;
  overflow-y: auto;

  @media (min-width: $screen-lg-up) {
    max-width: 30rem;
  }
}

.modal--access-code-challenge {
  max-width: 20rem;
}

.modal__overlay--has-visible-backdrop,
.modal--tooltip-like__overlay {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $z-index-responsive-tooltip-overlay;
}

.modal__overlay--has-visible-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal__overlay--has-dark-backdrop {
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-dialog {
  color: $brown-dark;
  max-width: calc(100% - 2rem);
  transform: scale(0.8);
  transition: all 0.3s ease-in;
  margin: auto;
  height: auto;

  @media (min-width: $screen-md-up) {
    max-width: 600px;
  }
}

.modal-dialog--smaller {
  max-width: 330px;
}

.modal-dialog--large {
  max-width: 80vw;
  width: 100vw;
}

.modal-dialog--after-open {
  transform: scale(1);
  opacity: 1;
}

.modal-dialog--before-close {
  opacity: 0;
  transform: scale(0.8);
}

.modal-dialog--purchase-fate {
  overflow: auto;

  @media (min-width: $screen-lg-up) {
    min-width: 600px;
  }
}

.modal-dialog--quality-picker {
  max-width: calc(100vw - 2rem);
  width: 100%;

  // Do some responsive/widescreen hax
  @media (min-width: $screen-lg-up) {
    max-width: 60rem;
  }
}

.modal-dialog__overlay {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: all 0.3s ease;
  height: 100%;
  width: 100%;
  display: grid;
}

.modal-dialog__overlay--after-open {
  opacity: 1;
  z-index: $z-index-modal-dialog-overlay;
}

.modal-dialog__overlay--before-close {
  opacity: 0;
}

.modal-dialog__close-button--media-large {
  position: absolute;
  right: 0;
  top: -8px;
}

.dialog__actions {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.dialog__actions button {
  margin: 0 5px;
}

.dialog__body {
  flex: 1;
}

.dialog__content {
  width: 100%;
}

.dialog__message {
  position: relative;
  float: left;
  width: 100%;
}

.dialog__media {
  flex-wrap: wrap;
  margin-top: 0;
}

.dialog--button {
  bottom: -20px !important;
  left: 50% !important;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  position: absolute !important;
}

.modal__content {
  max-height: 80vh;
  overflow-y: auto;
}
