.interactive-marker {

}

.interactive-marker__gate-icon,
.interactive-marker__lock-icon
{
  height: 21px;
  position: absolute;
  top: 8px; // account for container padding
  transition: filter 200ms;
  z-index: 5;
}

.interactive-marker__gate-icon--selected,
.interactive-marker__lock-icon--selected
{
  filter: sepia(1) saturate(2.4) hue-rotate(3deg);
}

.interactive-marker__gate-icon--unterzee {
  height: 30px;
  top: 4px;
}

.interactive-marker__gate-icon--roof {
  @extend .interactive-marker__gate-icon--unterzee;

  top: 48px;
}
