// speed of disclosure show/hide animation
$disclosure-speed: 0.3s;

.disclosure-wrapper {
  border-top: solid 1px $button-primary-bg;
  margin: 1rem 0;
}

.disclosure-container {
  width: 100%;
}

.disclosure-control {
  // give it basic appearance
  @extend .button;

  // only text gets colour
  @include button-variant($button-tertiary-color, transparent, transparent, transparent);

  // remove box-shadow to make it stop visually indenting like a button
  &,
  &:active,
  &.active {
    box-shadow: none;
  }

  // remove focus border (appears to be Firefox-only)
  &:focus {
    outline: none !important;
  }

  // other styling
  padding: 7px 0;
  width: 100%;
  text-align: right;
}

.disclosure-children {
  border-bottom: solid 1px $button-primary-bg;

  height: 0;
  overflow: hidden;
  transition-duration: $disclosure-speed;
  transition-property: height;
  transition-timing-function: ease-in-out;
}

.disclosure-wrapper--open {
  .disclosure-children {
    height: auto;
  }
}
