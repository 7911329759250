// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

.container {
	width: 100%;
	@include container-fixed;
	@media (min-width: $screen-lg-min) {
    // width: $container-lg;
	}
}