.travel {
  text-align: center;
}

.travel-button--infobar {
  margin: 10px 0 0;
  width: 100%;
}

.travel__message {
  padding: 12px 0;
  margin: 0 auto;
  font-weight: bold;
}
