/**
 * Nav Content
 *
 * The content areas that both .nav--tabbed and .nav--stacked let the user
 * navigate between.
 */


.stack-content {
  // @include make-sm-column(18);
  // We left a spare column when making .nav--stacked, so push over one
  // column; a bit more space.
  // This will add half a column of left margin:
  @include make-sm-column-offset(0.5);

  margin-bottom: 1em;

  @media (min-width: $screen-sm-min) {
    // We manually add half a column of left padding:
    padding-left: percentage(0.5 / $grid-columns);

    // And add a left border between the two:
    border-left: 2px solid $brown-light;
  }

  @media (min-width: $screen-lg-up) {
    float: left;
    margin-left: calc(100% * .5 / 24);
    padding-right: .5rem;
    width: calc(100% * 18.5/24);
  }
}

.tab-content__bordered-container {
  @include extra-border(before, 4px, 2px, #897d67);

  padding: 1rem;
}

// For JS switching between panes (in .tab-content or .stack-content) that
// exist in the HTML.
.tab-content,
.stack-content {
  > .tab-pane {
    display: none;
  }

  > .active {
    display: block;
  }
}
