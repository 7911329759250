.snippet {
  box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.5);
  border: 2px solid #3e3e3e;
  margin-top: 1rem;
  padding: .5rem .5rem 1rem;
  position: relative;
  text-align: left;

  @supports (hyphens: auto) {
    hyphens: auto;
    text-align: justify;
  }
}

.snippet__heading {
  color: #efefef;
  text-align: left;
}

.snippet__edit-button-container {
  float: right;
}

.snippet__refresh-button-container {
  display: flex;
  bottom: -18px;
  justify-content: center;
  position: absolute;
  width: calc(100% - 1rem); // compensate for container padding
}