/**
 * FOOTER
 * Only shown at narrow widths.
 */

$actions-height: 65px;
$actions-width: 65px;
$footer-button-padding: 4px;

// Shown up to 767px wide.
.footer-xs {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: $z-index-footer-xs;
    background: $footer-bg;
    width: 100%;
    height: $footer-xs-height;

    @media (min-width: $screen-sm-min) {
        display: none;
    }
}

.footer-menu {
    @extend .list--unstyled;

    bottom: 0;
    display: flex;
    justify-content: stretch;
    position: absolute;
    width: 100%;
}

.footer-menu__item {
    flex: 1;
    // display: table-cell;
    height: $footer-xs-height;
    // padding-left: 4px;
    // padding-right: 4px;
    vertical-align: bottom;

    // Assumes we have five <li>s in total:
    // width: 20%;

    > a,
    > button
    {
        display: block;
        height: $footer-xs-height;
        text-align: center;
        color: $footer-color;
        position: relative;

        &:hover,
        &:active {
            color: $footer-button-active-color;
        }

        .fa {
            line-height: $footer-xs-height;
        }

        .fl-ico {
            background-color: $footer-color;
        }
    }

    > .footer-menu__submenu {
        background: $footer-bg;
        position: absolute;
        top: -28px;
    }

    &.active {
        > a {
            background: $footer-button-active-bg;
            color: $footer-button-active-color;

            .fl-ico {
                background-color: $footer-button-active-color;
            }
        }
    }

    &.notifying{
        i{
            animation: throb 1s infinite alternate;
        }
    }

    &.disabled {
        opacity: 0.25;
    }
}

.footer-menu__item--actions {
    position: relative;
    top: -24px;

    .footer-menu__item__bubble {
        opacity: 0;
        transition: opacity 1s ease-in-out;
    }

    &.depleted {
        > a .footer-menu__item--actions--empty {
            opacity: 1;
        }

        .footer-menu__item__bubble {
            opacity: 1;
        }
    }

    > a,
    > button
    {
        height: 65px;
        width: 65px;
        bottom: 0;
        margin-left: auto;
        margin-right: auto;

        > .footer-menu__item--actions--empty {
            height: 65px;
            width: 65px;
            top: 0;
            opacity: 0;
            transition: all 1s ease-in-out;
        }

        > div {
            position: absolute;
            top: 43px;
            left: 0;
            width: 100%;
            color: $inverse-text-color;
            font-family: $font-family-serif;
            font-size: $font-size-base;

            & .action__count__item {
                width: 2em;
            }
        }
    }
}

.footer-menu__item--actions > a,
.footer-menu__item--actions > button
{
    background: transparent url("/img/footer-actions.png") 0 0 no-repeat;
}

// For the 'unread' messages number.
.footer-menu__item__bubble {
    position: absolute;
    top: 5px;
    left: 60%;

    @include bubble($brown-dark, $beige-light);
}

@keyframes throb {
    from {
       background-color: $footer-color;
    }
    to {
        background-color: $footer-button-active-color;
    }
}
