.general-container__row {
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (min-width: $screen-lg-up) {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
