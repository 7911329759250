.map-limbo {
  bottom: 0;
  cursor: default;
  display: flex;
  align-items: center;
  flex-direction: column;
  left: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  padding-bottom: 20px;
  padding-left: 20px;
  position: absolute;
  z-index: 600;
}

.map-limbo__player-marker {
  height: calc(159px / 2);
  pointer-events: none;
  width: calc(112px / 2);
}

.map-limbo__signpost {
  display: block;
}