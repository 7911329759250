/**
 * Styling for form controls.
 * Mostly so that they're more consistent across browsers.
 *
 * A bunch of this from
 * https://github.com/twbs/bootstrap-sass/blob/master/assets/stylesheets/bootstrap/_forms.scss
 *
 * Some examples of usage:
 *
 *	<form class="form--inline" action="#">
 *		<p class="form__group">
 *			<label for="my-id">Filter:</label>
 *			<input class="form__control" type="search" id="my-id">
 *		</p>
 *	</form>
 *
 *
 * Checkboxes, or do the same with .radio and a radio input:
 *
 * 	<p class="checkbox">
 *		<label>
 *			<input type="checkbox" name="my-name"> My label text
 *		</label>
 *	</p>
 */


 $form-control-color: #ede3d2;



label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
}

// Suppress numeric spinners on webkit browsers
input[type="number"] {
    -moz-appearance: textfield;
    -webkit-appearance: none; // i.e., no special styling
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="search"] {
    box-sizing: border-box;
}

input[type="radio"],
input[type="checkbox"] {
    margin: 4px 0 0;
    margin-top: 1px \9;
    line-height: normal;
}

input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

.form__control {
    display: inline-block;
    width: 100%;
    // height: 29px;
    padding: .5rem;
    font-size: 16px;
    line-height: 1.38462;
    color: $form-control-color;
    background-color: #282520;
    background-image: none;
    border: 1px solid #a89e8c;
}

.form__control--has-buttons {
    margin-bottom: .5rem;
}

.form__control--invalid {
    border-color: red;
}

.form__control--no-border {
    border: 0;
}

.form__control:focus {
    border-color: #7ebcc0;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(126, 188, 192, 0.6);
}

.form__control[disabled], .form__control[readonly], fieldset[disabled] .form__control {
    background-color: #bbbbbb;
    opacity: 1;
}

.form__control[disabled], fieldset[disabled] .form__control {
    cursor: not-allowed;
}

.panel .form__control {
    border-color: #756b5a;
}

textarea.form__control {
    display: block;
    height: auto;
    font-family: Roboto, Helvetica, Arial, sans-serif;
}

select.form__control {
    background-color: #756b5a;
    border-color: #756b5a;
}

input[type="search"] {
    -webkit-appearance: none;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    input[type="date"],
    input[type="time"],
    input[type="datetime-local"],
    input[type="month"] {
        line-height: 29px;
    }
}

.form__group {
    margin-bottom: 0.5em;
}

.radio,
.checkbox {
    position: relative;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
}

.radio label,
.checkbox label {
    min-height: 17px;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;
}

.radio input[type="radio"],
.checkbox input[type="checkbox"] {
    position: absolute;
    margin-left: -20px;
    margin-top: 4px \9;
}

.radio + .radio,
.checkbox + .checkbox {
    margin-top: -5px;
}

input[type="radio"][disabled], input[type="radio"].disabled, fieldset[disabled] input[type="radio"],
input[type="checkbox"][disabled],
input[type="checkbox"].disabled, fieldset[disabled]
input[type="checkbox"] {
    cursor: not-allowed;
}

.radio.disabled label, fieldset[disabled] .radio label,
.checkbox.disabled label, fieldset[disabled]
.checkbox label {
    cursor: not-allowed;
}

.form--inline {
    margin-bottom: 0.5em;
}

@media (min-width: 768px) {
    .form--inline .form__group {
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 0;
        margin-right: 10px;
    }

    .form--inline .form__control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }

    .form--inline .control-label {
        margin-bottom: 0;
        vertical-align: middle;
    }

    .form--inline .radio,
    .form--inline .checkbox {
        display: inline-block;
        margin-top: 0;
        margin-bottom: 0;
        vertical-align: middle;
    }

    .form--inline .radio label,
    .form--inline .checkbox label {
        padding-left: 0;
    }

    .form--inline .radio input[type="radio"],
    .form--inline .checkbox input[type="checkbox"] {
        position: relative;
        margin-left: 0;
    }

    .form--inline .button {
        padding: 5px 15px 5px 15px;
        font-size: 13px;
        line-height: 1.38462;
    }
}

.form__control--1h,
.form--inline .form__control--1h {
    width: 4em;
    text-align: right;
}

.form__control--1k,
.form--inline .form__control--1k {
    width: 4.2em;
    text-align: right;
}

.form__control--10k,
.form--inline .form__control--10k {
    width: 4.8em;
    text-align: right;
}

.form__error {
    color: #b11818;
}

/**
 * Rumbled! You found me!
 * A special case for the 'Remember me' checkbox on the login form on the home
 * page. At certain medium widths the checkbox-and-text was going above the
 * 'Login' button. So instead we move it over to the left, looking less
 * broken.
 */
@media (min-width: 768px) and (max-width: 991px) {
    .form--inline--login {
        display: block;
        text-align: left;
    }
}

option.option--disabled-but-legible:disabled {
    color: rgba($form-control-color, 0.75);
}
