/****************************************************************************
 * TYPE
 */

$font-family-sans-serif: Roboto, Helvetica, Arial, sans-serif;
// $font-family-sans-serif: Lato, Helvetica, Arial, sans-serif;
$font-family-serif: "Roboto Slab", Georgia, Times, serif;
$font-family-monospace: Monaco, Consolas, "Courier New", monospace;

// $font-size-base: 13px;
// $font-size-large: 15px;
// $font-size-larger: 18px;
// $font-size-largest: 20px;

$font-size-base: 14px;

// Let's try minor second for our font scale
// $font-size-base: 16px;
// $font-size-large: $font-size-base * 1.067;
// $font-size-larger: $font-size-base * 1.138;
// $font-size-largest: $font-size-base * 1.215;
$font-size-large: $font-size-base * 1.2;
$font-size-larger: $font-size-base * 1.44;
$font-size-largest: $font-size-base * 1.728;

// Buttons need to have a slightly smaller font-size so that they match up with the icon sizes
$font-size-button: 13px;

// $line-height-base: 1.3846153846; // 18/13
$line-height-base: 1.45;
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed: floor($font-size-base * $line-height-base); // ~20px

$font-weight-base: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
