.outfit-select-container {
  background-color: #282520;
  color: $form-control-color;
  flex: 1;
  max-width: 12rem;
}

.outfit-select.outfit-select {
}

.outfit-select__control.outfit-select__control {
  background-color: #756b5a;
  border-color: #756b5a;
  // @extend .form__control;
  // @extend .outfit-controls__outfit-dropdown;
}

.outfit-select__value-container.outfit-select__value-container {

}

.outfit-select__indicators.outfit-select__indicators {

}

.outfit-select__menu.outfit-select__menu {
  background-color: #756b5a;
  color: $form-control-color;
  padding-top: 0;
}

.outfit-select__menu-list {

}

.outfit-select__option {

}

.outfit-select__single-value {
  color: $form-control-color;
}