.outfit-selector {
  margin-bottom: 1rem;
  max-width: 12rem;
}

.outfit-selector {
  max-width: none;
}

.outfit-selector--disabled {
  align-items: center;
  background-color: transparent;
  cursor: not-allowed;
  display: flex;
  justify-content: space-between;
}

.outfit-selector--is-changing {
  opacity: 0.5;
  pointer-events: none;
}

.outfit-selector__title {
  display: block;
  font-family: $font-family-serif;
  font-weight: $font-weight-bold;
  margin-bottom: 4px;
  padding-left: calc(.5rem + 4px);
}
