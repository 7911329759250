.metablurb {
  font-style: italic;
}

.metablurb__text {
  margin-bottom: 0;
}

.metablurb__faction-list {
  list-style-position: inside;
  margin-bottom: 10px;
  margin-left: 4px;
}

.metablurb__faction-name {
  position: relative;
  left: -4px;
}