/**
 * Progress bars
 *
 * Simple bar on its own:
 *
 * <span class="progress-bar progress-bar--menace">
 *		<span class="progress-bar__stripe" style="width:65%"></span>
 * </span>
 *
 * Or surround .progress-bar in .progress etc, to enable having a score to the
 * left of the bar:
 *
 * <div class="progress">
 *		<div class="progress__current">
 *			7
 *		</div>
 *		<div class="progress__bar">
 *			<span class="progress-bar progress-bar--menace">
 *				<span class="progress-bar__stripe" style="width:65%"></span>
 *			</span>
 *		</div>
 * </div>
 *
 */

/****************************************************************************
 * Simple progress bar.
 */

// The 'background', stretches full width.
.progress-bar {
  display: block;
  position: relative;
  width: 100%;
  height: 10px;
  padding: 2px;
  margin: 0.3em 0;
  background: $gray-darkest;
  border-bottom: 1px solid $brown-dark;

  // Similar shades are used further down in .progress__target.
  .media--priority-1 & {
    background: darken($media-priority-1-bg, 5%);
    border-bottom-color: lighten($media-priority-1-bg, 20%);
  }

  .media--priority-2 & {
    background: darken($media-priority-2-bg, 2%);
    border-bottom-color: lighten($media-priority-2-bg, 20%);
  }

  .media--priority-3 & {
    background: darken($media-priority-3-bg, 2%);
    border-bottom-color: lighten($media-priority-3-bg, 20%);
  }
}

.progress-bar--light-background {
  background: $brown-mid;
  border-bottom-color: $brown-mid;
}

// The coloured stripe within the bar.
.progress-bar__stripe {
  display: block;
  background-color: $progress-default-bg;
  height: 6px;
  border-top: 1px solid $progress-default-border;
  border-radius: 1px;
  max-width: 100%;
}

.progress-bar__stripe--has-transition {
    transition: width 2s ease;
}

.progress-bar__stripe--menace {
  background-color: $progress-menace-bg;
  border-top: 1px solid $progress-menace-border;
}

.progress-bar__stripe--target {
  background-color: $progress-target-bg;
  border-top: 1px solid $progress-target-border;
}

/****************************************************************************
 * Extra stuff to go around/next to .progress-bar to add a score to its left.
 */

.progress {
  display: table;
}

.progress__current {
  display: table-cell;
  vertical-align: middle;
  border-radius: 13px;
  padding: 3px 4px 2px 4px;
  min-width: 23px;
  text-align: center;
  background: $brown-dark;
  color: $beige-mid;

  .bastard & {
    color: $beige-light;
  }
}

// And the .progress-bar goes within this:
.progress__bar {
  display: table-cell;
  width: 10000px; // Keep it as wide as possible.
  vertical-align: middle;
  padding-left: 5px;
  padding-right: 5px;
}
