/**
 * ICONS -AGAIN!
 *
 * These are the replacements for font icons. They use a similar syntax to Font Awesome
 *
 */


/****************************************************************************
 * Primary class.
 */

.fl-ico {
  display: inline-block;
  width: $base-icon-size;
  background-color: $black;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: 100%;
  height: $base-icon-size;
  transform: translate(0, 5px);

  &.fl-ico-2x {
    width: $base-icon-size * 2;
    height: $base-icon-size * 2;
  }

  &.fl-ico-3x {
    width: $base-icon-size * 3;
    height: $base-icon-size * 3;
  }
}

.fl-ico-list {
  padding-left: 0;
  margin-left: 42px;
  list-style-type: none;

  li {
    position: relative;
    margin-bottom: 0;

    .fl-ico {
      position: absolute;
      left: -42px;
      text-align: center;
      transform: none;
    }

    span {
      min-height: 32px;
      line-height: 40px;
      display: inline-block;
    }
  }
}

.fl-ico-save-outfit {
  background-color: transparent;
  //noinspection CssUnknownTarget
  background-image: url("/img/icons/ico_save_outfit.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 23px;
  left: 5px;
  top: 0;
  width: 23px;
}

.fl-ico-world {
  height: 18px;
}

.fl-ico-globe {
  height: 20px;
}