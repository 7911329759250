.lodgings-subarea {
  padding: 0 10px;
  pointer-events: auto;
  &:first-child {
    margin-right: 20px;
  }
}

.lodgings-subarea__selected-underline {
  bottom: -10px;
  left: 10px;
  position: absolute;
}
