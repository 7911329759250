.storylets__welcome-and-travel {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  & a {
    color: $blue-dark;

    :hover {
      color: lighten($blue-dark, 15%);
    }
  }
}
