// This irritating bit of specificity is because we have react-waypoint elements
// that add their own DOM elements, and so we wrap inventory groups in a container.
// As a result, we can't just do .inventory-group:last-child
.inventory-group-container {
    &:last-child {
        .inventory-group {
            border-bottom: none;
        }
    }
}

.inventory-group {
    border-bottom: solid 2px $brown-light;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
}
