// Zoom controls. We need to hack the specificity because our stylesheets get
// processed before Leaflet's.

.leaflet-bar.leaflet-bar a:last-child {
  border-radius: 0;
}

.leaflet-bar.leaflet-bar a:first-child,
.leaflet-bar.leaflet-bar a:last-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

