.second-chance {
  // background-color: #cbc3b3;
  display: flex;

  // padding: 5px 8px 8px;

  // Don't clear when the screen is just wide enough to float without linebreaks
  @media (min-width: 480px) {
    clear: none;
    display: flex;
    flex-wrap: nowrap;
  }

  @media (min-width: $screen-sm-up) {
    flex: 1;
  }
}

.second-chance__icon {
  position: relative;

  img {
    height: 30px;
    width: 30px;
  }
}

.second-chance__left {
  float: left;
  margin-left: .25rem;
  margin-right: .5rem;

  @media (min-width: $screen-sm-min) {
    float: none;
    margin-left: 0;
  }
}

input.second-chance__checkbox {
  margin-right: .5rem;
  margin-top: 0;
}
