/**
 * MISC UTILITIES.
 * Break things into separate files if there are several related things.
 */

.u-text-left		{ text-align: left; }
.u-text-right		{ text-align: right; }
.u-text-center		{ text-align: center; }
.u-text-nowrap		{ white-space: nowrap; }

@media (min-width: 768px) {
  .u-text-right-sm {
    text-align: right; }

  .u-text-center-sm {
    text-align: center; } }

.u-pull-left		{ float: left; }
.u-pull-right		{ float: right; }

// Used by JS to make .js-revealer's look more reavealable.
.u-clickable		{ cursor: pointer; }

// To indicate premium content.
.u-premium			{ color: $premium-color; }

.u-space-above {  margin-top: 1.5em; }

.u-space-below { margin-bottom: 1.5em; }

.descriptive {font-style: italic; font-weight: bold;}
