.purchase-panel {
  padding: .5rem;
  max-height: 80vh;
  overflow: auto; // we need this so that the panel will overflow after the Braintree UI bits are in
  width: 100%;
}

.enhanced-text {
  color: $enhanced-dark-color;
}

.enhanced-text--inverse {
  color: $enhanced-main-color;
}

.ef-buttons {
  align-items: center;
  clear: both;
  display: inline-grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 10px;
  justify-content: space-between;
  text-align: left;
  padding-top: 1rem;

  @media (min-width: $screen-sm-min) {
      clear: none;
      width: 100%;
  }

  &::after {
      content: '';
      flex: 10;
  }
}

.ef-benefits {
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  padding: 1rem 0;
}

.ef-subscribe {
  padding: 1rem 0;
}

.ef-benefits h2,
.ef-subscribe h2 {
  font-weight: bold;
}

.ef-benefits ul,
.ef-subscribe ul {
  padding-left: 1rem;
}

.ef-all-subscriber-benefits {
  padding: 0 0 1rem;
}

.button--ef {
  @include button-variant($enhanced-light-color, $enhanced-button-color, $enhanced-button-color, $enhanced-light-color);
}

.ef-subscription-panel {
  width: 100%;
}

.ef-subscription-options {
  display: inline-grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
}

.ef-subscription-options select {
  border: 2px solid #666;
  border-radius: 2px;
  margin-left: 0;
}

.ef-subscription-options label {
  @extend .lede;
}

.ef-subscription-detail {
  align-self: self-end;
}

.ef-subscription-lede {
  @extend .lede;

  font-size: $font-size-base;
}
