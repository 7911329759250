.journal-date-picker {
  .react-calendar {
    background-color: #bdb29e;
    box-shadow: 0 0 12px #222;
    color: #2b2b2b;
    font-family: $font-family-serif;
  }

  .react-calendar__navigation {
    border-bottom: solid 1px #2b2b2b;
    margin-left: .25rem;
    margin-right: .25rem;
  }

  .react-calendar__navigation__arrow {
    font-weight: 700;
    text-align: center;
  }

  .react-calendar__navigation button {
    background-color: transparent;
    color: $inverse-link-color;
    transition: all 180ms;
  }

  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus,
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #3f7277;
    color: #efefef;
  }

  .react-calendar__navigation__label {
    font-family: $font-family-serif;
    font-weight: 700;
    text-align: center;
  }

  .react-calendar__tile--hasActive,
  .react-calendar__tile--active {
    &, &:enabled:hover, &:enabled:focus {
    background-color: rgba(63, 114, 119, 1);
    color: #efefef;
    }
  }

  .react-calendar__tile .react-calendar__month-view__days__day:hover {
    background-color: rgba( 63, 114, 119, 0.5);
  }


  .react-calendar__month-view__days__day--weekend {
    color: inherit;
  }

  .react-date-picker {
    font-family: $font-family-sans-serif;
  }

  .react-date-picker__inputGroup,
  .react-date-picker__clear-button {
    display: none;
  }

  .react-date-picker__wrapper {
    border: none;
  }

  .react-date-picker__button:enabled {
    svg g {
      stroke: $link-color;
    }

    &:hover svg g {
      stroke: $blue-darker;
    }
  }
}
