.possessions-lodgings__border > div {
  padding: 14px;
  position: relative;
}

.possessions-lodgings__border > div:after {
  border-radius: 2px;
  bottom: 0;
  box-shadow: inset 0 0 4px black;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.possessions-lodgings__image {
  border-radius: 2px;
  display: block;
  height: 220px;
  width: 170px;
}
