/**
 * ICONS
 *
 * Icons are often used with a .item
 *
 * Each icon has three classes:
 *
 * eg:
 *	<span class="icon icon--quality icon--shadowy"></span>
 *
 *	.icon			The base class.
 *	.icon--quality  A second class indicating which kind of icon it is
 *					(and so which sprite sheet to load).
 *  .icon--shadowy	A third class indicating the specific icon
 *					(and so which position on the sprite sheet).
 *
 * Then there is an optional size:
 *
 *	.icon--sm		Small icon.
 *
 * And the icon might be 'locked':
 *
 *	.icon--locked	Locked icon.
 *
 */

/****************************************************************************
 * Primary class.
 */
//ICONS
// *
// * Each icon has one base class, and maybe additional ones:
// *
// * eg:
// *	<div class="icon icon--circular">
// *		<img src="..." alt="...">
// *	</div>
// *
// *	.icon			The base class.
// *
// * Then there are some optional style-related classes:
// *
// *  .icon--circular	Round icon.
// *
// *	.icon--locked	Locked icon.
// *
// *	.icon--emphasize Add extra border.
// *
// * Some special types of icons could have an extra class:
// *
// *	.icon--currency
// *
// *	.icon--inventory
// *
// */
/****************************************************************************
 * Primary class.
 */
.icon {
  display: inline-block;
  background-repeat: no-repeat;
  vertical-align: middle;
  line-height: 1px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}

.icon img {
  width: 36px;
  height: 36px;
  transform: translateZ(0);
}

/****************************************************************************
 * Optional variants.
 */

 .icon--available-item {
   img {
     border: solid 2px rgba(56, 56, 56, 1);
     transform: translateZ(0);
   }

   &:hover img {
     transform: scale(1.111111111);
   }
 }

.icon--circular {
  box-shadow: none;
}

.icon--circular img {
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}

.icon--world-quality {
  border-radius: 0 8px 0 8px;
}

.icon--world-quality img {
  border-radius: 0 8px 0 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}

.icon--world-quality > div {
  border-radius: 0 8px 0 8px;
}

.icon--currency {
  box-shadow: none;
}

.icon--locked {
  opacity: 0.5;
}

.icon--emphasize {
  position: relative;
  z-index: 0;
}

.icon--disabled {
  color: grey;
}

// Icons that should have CSS transitions
.icon--has-transition {
  transition: color 200ms;
}

.icon--inventory {
  border: solid 2px rgba(56, 56, 56, 1);
}

.icon--non-interactive {
  // Override react-interactive
  & > div {
    cursor: default !important;
  }

  & img {
    height: 40px;
    width: 40px;
  }
}

/****************************************************************************
 * Some special types of icon.
 */
.icon--cameo img {
  width: 40px;
  height: 52px;
}

.icon--cameo.icon--sm img {
  width: 30px;
  height: 39px;
}

.icon--currency img {
  box-shadow: none;
  width: 45px;
  height: 55px;
}

.icon--fate img {
  width: 78px;
  height: 100px;
}

.icon--purchase img {
  width: 30px;
  height: 30px;
}

.icon--available-item,
.icon--cameo,
.icon--inventory,
.icon--fate {
  position: relative;
  z-index: 0;
}

.icon--usable {
  @include extra-border(after, -3px, 4px, #81bc4e);

  cursor: pointer;
  transform: translateZ(0);
  transition: transform .1s ease-in;

  // No extra borders on usable images --- they get their borders from the parent component
  img {
    border: none;
  }

  &:hover {
    &:after,
    img {
      image-rendering: pixelated;
      transform: scale(1.111111) translateZ(0);
    }
  }
}

.icon--blocked {
  @include extra-border(after, -2px, 4px, $red-light);
}

.icon--is-loading {
  opacity: 0.5;
}

/***************
For price
*/
.price:before {
  content: '';
  display: inline-block;
  width: 1.2em;
  height: 1.8em;
  margin: 0 0 -0.8em 0;
  background-size: 1.75em;
}

.price--inverted:before {
  -webkit-filter: invert(1);
}

/****************************************************************************
 * Display a bubble number over the icon.
 *
 * (But for .icons within .items, you *might* want to use
 * .item__value within a .items--inline list...)
 */
.icon__value {
  font-family: $font-family-serif;
  z-index: 1;
  line-height: 1.3em;
  position: absolute;
  font-size: 10px;
  border-radius: 11px;
  padding: 2px 4px;
  min-width: 17px;
  text-align: center;
  background: #282520;
  color: #ede3d2;
  bottom: -5px;
  right: -5px;
}

.bastard .icon__value {
  color: #ede3d2;
}

/**
 * Quite generic styles for images.
 */
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.img-shadow {
  box-shadow: 0 2px 3px 2px rgba(0, 0, 0, 0.6);
}

/**
 * Apply to an element OUTSIDE the img.
 * eg, <div class="img-bordered"><img ...></div>
 */
.img-bordered {
  display: inline-block;
  line-height: 1px;
  position: relative;
  z-index: 0;
}

.img-bordered:before {
  content: " ";
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

/**
 * Apply to images (or a container around them) to give them some space.
 * Used if it's a 'grid' of identically-sized images.
 */
.img-spaced {
  margin: 0.5em 12px 0.5em 0;
}

@media (min-width: 480px) {
  .img-spaced {
    margin: 1em 20px 1em 0;
  }
}

@media (min-width: 768px) {
  .img-spaced {
    margin: 1em 30px 1em 0;
  }
}

.icon--available-item.icon--disabled {
  box-shadow: none;

  &:hover {
    img {
      transform: none;
    }
  }

  img {
    border-color: $brown-light;
    border-width: 1px;
  }
}