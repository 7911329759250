// CSS for displaying Boons and Burdens

.effect-group__items {
  display: block;
  padding: 0 3px;
  margin: 10px 0 0;
}

.effect-item-list {
  display: inline-grid;

  // grid-template-columns is defined by a separate class (see below)
  grid-auto-rows: 48px;

  gap: 5px;
  list-style: none;
  border: solid 1px $brown-light;
  margin-bottom: 8px;
  padding: 6px 0 0 6px;
}

.effect-item-list__item {
  display: inline;
  padding: 0;
  margin: 0;
  width: fit-content;
}

.effect-item {
  position: relative;
  z-index: 0;
  display: inline-block;
  background-repeat: no-repeat;
  vertical-align: middle;
  line-height: 1px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}

.effect-item__image {
  border: solid 2px #383838;
}

/*
 * Normally for a grid layout, we could just use...
 *
 *  display: grid;
 *  grid-template-columns: repeat(auto-fill, 48px);
 *
 * ... and call it a day, but we want to draw a border around the items
 * within the grid, and that CSS won't do.
 *
 * First, we have to use...
 *
 *  display: inline-grid;
 *
 * ... in order to prevent the grid from automatically taking up 100%
 * of the available width; if that happens, then grids with only a few
 * items will look odd, as the border will encompass too much
 * horizontal space.
 *
 * Second, auto-fill causes the exact same problem: it automatically
 * "reserves" enough space to fill all available width, leading to the
 * same border that crosses the screen, even if only one item is in it.
 *
 * A third problem is that, in the absence of auto-fill, we must
 * specify a fixed number of columns.  We already require a variable
 * number of rows, and with no guidance on either rows *or* columns,
 * the user-agent (i.e., browser) has to make a judgment call. It
 * appears the standard implementation in this scenario is to assume
 * one column, resulting in a vertical list (which we don't want).
 *
 * There is no way to know in advance how many items will appear in the
 * grid (or put another way, how many child nodes the grid will
 * contain), so we must resort to defining a CSS class for each number
 * of columns from 1 to 16.  The upper limit of 16 was chosen because
 * past a certain point, the available horizontal space stops growing,
 * and at that maximum width, auto-fill generates 16 columns.
 *
 * Finally, media queries are used to ensure items will wrap correctly
 * at various screen widths.  (The use of mixins is to avoid copy-paste
 * errors within the media queries.)
 */

// simple mixin to ensure columns are always the same width
@mixin effect-item-list-grid-template-columns($count) {
  grid-template-columns: repeat($count, 48px);
}

// an 'all-in-one' mixin that determines grid width based on number of items and screen width
@mixin effect-item-list-columns($columns) {
  @if $columns < 8 {
    // fewer than 8 columns means media queries have no effect on the size
    @include effect-item-list-grid-template-columns($columns);
  } @else {
    // recursion to get all prior media queries
    @include effect-item-list-columns($columns - 1);

    // basic amount of horizontal space required
    $min-width: $columns * 53px + 48px;

    @if $columns > 13 {
      // additional space required when changing display formats
      $min-width: $min-width + 474px;
    }

    // new media query for this column
    @media (min-width: $min-width) {
      @include effect-item-list-grid-template-columns($columns);
    }
  }
}

// the actual CSS classes establishing grid width, which drives the border size
.effect-item-list-columns-1 {
  @include effect-item-list-columns(1);
}

.effect-item-list-columns-2 {
  @include effect-item-list-columns(2);
}

.effect-item-list-columns-3 {
  @include effect-item-list-columns(3);
}

.effect-item-list-columns-4 {
  @include effect-item-list-columns(4);
}

.effect-item-list-columns-5 {
  @include effect-item-list-columns(5);
}

.effect-item-list-columns-6 {
  @include effect-item-list-columns(6);
}

.effect-item-list-columns-7 {
  @include effect-item-list-columns(7);
}

.effect-item-list-columns-8 {
  @include effect-item-list-columns(8);
}

.effect-item-list-columns-9 {
  @include effect-item-list-columns(9);
}

.effect-item-list-columns-10 {
  @include effect-item-list-columns(10);
}

.effect-item-list-columns-11 {
  @include effect-item-list-columns(11);
}

.effect-item-list-columns-12 {
  @include effect-item-list-columns(12);
}

.effect-item-list-columns-13 {
  @include effect-item-list-columns(13);
}

.effect-item-list-columns-14 {
  @include effect-item-list-columns(14);
}

.effect-item-list-columns-15 {
  @include effect-item-list-columns(15);
}

.effect-item-list-columns-16 {
  @include effect-item-list-columns(16);
}
