.fate-header {
  align-items: flex-start;
  border-bottom: solid 2px $brown-light;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
}

.fate-header__subscription-container {
  @media (max-width: ($screen-sm-up - 1)) {
    margin-top: 0.5rem;
  }
}

.fate-header__text {
  flex: 1;
}
