.small-card-container {
    display: flex;
}

.small-card__body {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.small-card__border {
    padding: 7px 7px 6px 7px;
}

// Here we are targeting the wrapper div created by react-interactive
.small-card__border > div {
    position: relative;
}

// To cover the seam between images and card-border backgrounds, we
// add a div with a small inset box-shadow
.small-card__border > div:after {
    border-radius: 1px;
    bottom: 0;
    box-shadow: inset 0 0 2px black;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.small-card__image {
    display: block;
    height: 99px;
    width: calc(99px * 100/130);
}

.small-card__left {
    padding-bottom: 0;
}

.small-card__teaser {
    flex: 1;
    hyphens: auto;
    text-align: justify;
}