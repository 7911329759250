.action-count-modal {
  background: transparent;
  color: #efefef;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding-top: 4rem;
  max-width: 30rem;
  width: calc(100% - 4rem);
}

.action-count-modal__close-button-container {
  display: flex;
  justify-content: center;
  padding-bottom: 7px;
  width: 100%;
}

.action-count-modal__close-button {
  align-items: center;
  background: transparent;
  border: solid 3px #efefef;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 60px;
  width: 60px;
}

.action-count-modal__count {
  font-size: $font-size-large;
}

.action-count-modal__next-action-container {
  color: $beige-mid;
  font-family: $font-family-serif;
  font-size: $font-size-large;
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 4px solid rgba(255, 255, 255, 0.8);
  text-align: center;
}

.action-count-modal__next-action-time {
  color: white;
  font-size: $font-size-larger;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.fl-ico.action-count-modal__icon {
  background-color: #efefef;
}
