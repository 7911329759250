.inner-tabs {
  border-bottom: solid 2px $brown-light;
  display: flex;
  flex-wrap: wrap; // For *very* small screens
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
}

.inner-tab {
  align-items: baseline;
  background-color: transparent;
  color: $blue-darker;
  cursor: pointer;
  display: flex;
  flex: 1;
  padding: 0;
  transition: color 200ms;

  &:focus {
    .inner-tab__label {
      border-radius: 1px;
      outline: $blue-darker solid 2px;
      outline-offset: 2px;
    }
  }

  .inner-tab__icon {
    background-color: $blue-darker;
    transition: background-color 200ms;
  }
}

.inner-tab--active {
  color: $brown-dark;

  &:focus {
    .inner-tab__label {
      outline-color: $brown-dark;
    }
  }

  .inner-tab__icon {
    background-color: $brown-dark;
  }
}

.inner-tab--fate {
  padding-left: 0.5rem;

  @media(max-width: $screen-sm-down) {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 0;
  }
}

.inner-tab--with-border {
  border-right: 2px solid $brown-light;
  margin-right: 2%;
}

.inner-tab--with-border--fate {
  margin-right: 0;
}

.inner-tab--no-icon {
  align-items: flex-end;
  min-height: 32px;
}

.inner-tab__icon {
  margin-right: 12px;
}

.inner-tab__icon--fate {
  @media (max-width: $screen-sm-down) {
    margin-right: 0;
  }
}

.inner-tab__icon--star {
  position: relative;
  top: -2px;
}

.inner-tab__label {
  bottom: 7px;
  font-size: 17px;
  font-weight: bold;
  position: relative;
}

.inner-tab__label--fate {
  @media(max-width: $screen-sm-down) {
    margin-top: 12px;
    text-align: center;
  }
}
