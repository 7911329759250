/****************************************************************************
 * STRUCTURE
 *
 * Using Bootstrap 3's grid system: http://getbootstrap.com/css/#grid
 *
 * We could add grid classes directly to elements in the HTML, eg:
 *	<div class="col-xs-12 col-md-8">My column</div>
 *
 * But we're using the mixins so that our HTML will be nice and semantic.
 *
 * There should be a .container around everything that uses the grid.
 * Then a .row for each row. eg:
 *
 * <div class="container">
 *	<div class="row">
 *		<div class="col-primary"></div>
 *		<div class="col-secondary"></div>
 *		<div class="col-tertiary"></div>
 *	</div>
 *	<div class="row">
 *		etc...
 *	</div>
 * </div>
 */

/****************************************************************************
 * Main structure for the pages.
 */

.col-primary {
    flex: 5;
      
    margin: 0;
    max-width: 750px;

    // Hey, IE 11, don't go crazy please
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      max-width: none;
    }

    // TODO: This is the full-screen width, derived somehow from the rest of the layout CSS...
    // but why?
    width: 100%;

    @media (min-width: $screen-lg-up) {
        margin-left: 2rem;
    }

    @media (min-width: $screen-xl-up) {
      flex: 4;
      // Add a margin-right to separate it from the infobar column
      margin-right: 2rem;
      max-width: 900px;
        // Seriously, DO NOT screw this up, IE
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            max-width: none;
        }
    }
}

.col-secondary {
    // At smallest width, not visible (because we have similar content in
    // mobile sidemenu.
    display: none;
    flex: 1;
    max-width: calc(.16 * 1180px);

    // .col-secondary needs to be relative so that we can position the action candles
    position: relative;

    @media (min-width: $screen-lg-up) {
        display: block;
        margin-top: 2.5em;
    }
}

.col-tertiary {
    display: none;
    flex: 1;
    max-width: calc(.16 * 1180px);

    @media (min-width: $screen-xl-up) {
        display: block;
    }
}

/****************************************************************************
 * Other structury things.
 */

.img-responsive {
    @include img-responsive;
}