.equipped-item {
  position: relative;
}

.equipped-item--lacks-selected-enhancement {
  opacity: 0.5;
}

.equipped-item__image {
  border: solid 2px #383838;
  display: block;
}

.equipped-item__image:hover {
  transform: scale(1.05);
}

.equipped-item__image--disabled:hover {
  cursor: default;
  transform: none;
}

.equipped-item__image--unchangeable:hover {
  cursor: default;
  transform: none;
}
