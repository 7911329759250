.news {
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-flow: row wrap;
  padding: 8px;
  margin-right: 20px;
  margin-bottom: 20px;
  max-width: 80vw;
  background: #1d1d1d;
  box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.5);
  border: 2px solid #3e3e3e;
  width: 400px;
  // TODO: saner z-index
  z-index: 1000;
  word-wrap: break-word;

  @media (max-width: $screen-sm-down) {
    // Centre horizontally on phones
    left: 50%;
    transform: translateX(-50%);
  }
}

.news img {
  margin: 5px;
  border: 2px solid #3e3e3e;
}

.news-dismiss {
  float: right;
}

.news-body {
  flex: 1 70%;
}

.news__close-button {
  position: absolute;
  right: 8px;
  top: 8px;
}
